import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import styles from "assets/jss/material-kit-react/components/headerStyle.js";
import "./Header.css"

//6-jul-2023
import useWindowDimensions from "../../useWindowDimensions";

const useStyles = makeStyles(styles);

export default function Header(props) {
  const classes = useStyles();
  const { width } = useWindowDimensions();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  };
  const { color, rightLinks, leftLinks, brand, fixed, absolute } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  });
  const brandComponent = <Button className={classes.title}>{brand}</Button>;
  return (<>
    
    <AppBar className={appBarClasses}
    //  style={{marginTop:"1.8%"}}
     >
   {/* {props.login!="yes" ?  <div  style={{width:"100%",backgroundColor:"#196c90",position:"absolute",top:"-1%",height:width<960?"35%":"20%",display:width<960?"none":""}}><p className="news-item" style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",alignSelf:"center",textAlign:"center"}}><p style={{fontSize:width<1150?"90%":""}} >Metropole Laboratories (Pvt) Ltd</p><p style={{marginLeft:width<1350?"5%":"10%",fontSize:width<1150?"90%":""}}>Committed to Diagnostic Excellence</p><p style={{marginLeft:width<1350?"5%":"10%",fontSize:width<1150?"90%":""}}>ISO 15189:2012 Accredited & ISO 9001:2015 Certified Lab</p><p style={{marginLeft:width<1350?"5%":"10%",fontSize:width<1150?"90%":""}}>24/7 Call 051-111-988-988</p></p></div>
:

<></>  } */}
    {/* <div  style={{width:"100%",backgroundColor:"#196c90",position:"absolute",top:"-1%",height:width<960?"35%":"20%",display:width<500?"none":""}}><p className="news-item" style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",alignSelf:"center",textAlign:"center"}}><p style={{fontSize:width<1150?"90%":""}} >Metropole Laboratories (Pvt) Ltd</p><p style={{marginLeft:width<1350?"5%":"10%",fontSize:width<1150?"90%":""}}>Committed to Diagnostic Excellence</p><p style={{marginLeft:width<1350?"5%":"10%",fontSize:width<1150?"90%":""}}>ISO 15189:2012 Accredited & ISO 9001:2015 Certified Lab</p><p style={{marginLeft:width<1350?"5%":"10%",fontSize:width<1150?"90%":""}}>24/7 Call 051-111-988-988</p></p></div> */}

      <Toolbar className={classes.container}
     style={{marginTop:width<500?"1.5%":width<960?"2.5%":"1.5%"}}
    // style={{marginTop:"1.5%"}}

    >
        {leftLinks !== undefined ? brandComponent : null}
        <div className={classes.flex}>
          {leftLinks !== undefined ? (
            <Hidden smDown implementation="css">
              {leftLinks}
            </Hidden>
          ) : (
            // <img src={require("assets/img_stock/New2023/mpl-logoNew.png")} width="200px" />
            <NavLink to="/" >
            
            <img src={require("../../images/new/JTEL Header Block.jpg")} style={{maxWidth:width<500?"260px":width<1000?"250px":"300px",paddingBottom:"0px",marginTop:width<500?0:-22}} /> 
            </NavLink>

            // 18-aug-2023 es se laptop pe thk aa gae

            // <img src={logoNew} 
            // width={width<500?"50%":""}
            // height={width<500?"50%":""}
            //    />

          )}
        </div>
        <Hidden smDown implementation="css">
          {rightLinks}
        </Hidden>
        <Hidden mdUp>
          <IconButton
            color="white"
            style={{backgroundColor:'#cb1a2c'}}
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu  style={{color:"white"}}/>
          </IconButton>
        </Hidden>
      </Toolbar>
      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={handleDrawerToggle}
        >
          <div className={classes.appResponsive}>
            {leftLinks}
            {rightLinks}
          </div>
        </Drawer>
      </Hidden>
    </AppBar>
    </>
  );
}

Header.defaultProp = {
  color: "primary"
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark"
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark"
    ]).isRequired
  })
};
