import React, { useEffect, Fragment,useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
//24-march-2023 for acs desc
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Autocomplete from "@material-ui/lab/Autocomplete";
import MaterialTable from "material-table";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import useWindowDimensions from "../../useWindowDimensions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from "@material-ui/core/Checkbox";
import image from "../../images/new/JTEL Header Block.jpg"

import MenuItem from '@material-ui/core/MenuItem';


// import Grid from "@material-ui/core/Grid";


import HelpIcon from '@material-ui/icons/Help';
import DescriptionIcon from '@material-ui/icons/Description';

import { isMobile } from 'react-device-detect';
import TableRow from '@material-ui/core/TableRow';
import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp';
import { NavLink } from "react-router-dom";
import { Link,useHistory } from "react-router-dom";



import Button from '@material-ui/core/Button';
import Datetime from 'react-datetime'
import ReactLoading from 'react-loading';
// import Invoice from './PDF/Invoice.js'


import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";

import Slide from "@material-ui/core/Slide";

import { PDFViewer } from '@react-pdf/renderer'

import { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import SearchIcon from '@material-ui/icons/Search';

import Select from '@material-ui/core/Select';

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import DateFnsUtils from '@date-io/date-fns';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import AutorenewIcon from '@material-ui/icons/Autorenew';
import { GridList } from '@material-ui/core';
// import CovidTemplate from './Covid_PDF/CovidTemplate'
// import TemplateCertificate from './TemplateCertificate/TemplateCertificate'
import GroupIcon from '@material-ui/icons/Group';
// import CertificateTemplate from './Certificate/CertificateTemplate'

// import SignCovidTemplate from './Sign_Covid_PDF/SignCovidTemplate'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

// import Covid_AntiGen from './Covid_AntiGen/CovidTemplate'
import { bool } from 'prop-types';
import { ProductContex } from '../../ContextStore.js';


import TextField from '@material-ui/core/TextField'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
var id = localStorage.getItem('patient_name') + ""





const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 1000,
  },
});
function msToTime(ms) {
  var seconds = (ms / 1000);
  var minutes = parseInt(seconds / 60, 10);
  seconds = seconds % 60;
  var hours = parseInt(minutes / 60, 10);
  minutes = minutes % 60;

  return hours + ':' + minutes;
}

export default function Lab_Tests({}) {
  const {dispatcchUserEvents,billAmount,cartItems,TotalAmount,TotalDiscount,TotalPayable,TotalPayableAddDisc,TotalRfdAmount,TotalRfdPayable,TotalRfdPayableAddDisc,TotalRefundDiscountAmount} = useContext(ProductContex);
  var from = new Date();

  const classes = useStyles();
  const { width } = useWindowDimensions();
  const [client_title, setclient_title]= React.useState('')

  const [person_name, setperson_name]= React.useState('')
  const [address_1 , setaddress_1]= React.useState('')
  const [address_2 , setaddress_2]= React.useState('')

  const [city, setcity]= React.useState('')
  const [state, setstate]= React.useState('')
  const [zip, setzip]= React.useState('')
  const [phone, setphone]= React.useState('')
  const [email, setEmail]= React.useState('')
  const [houseno, setHouseNo]= React.useState('')


  const [user_id, setuser_id]= React.useState('')
  
 
   // this is for Previous Invoice Selection

  const [loading,setLoading]=React.useState(false)

  const history = useHistory();




  useEffect(()=>{
console.log("yess")
if(localStorage.getItem('user_id')  ){
  // window.location.href="https://mpl-labs.pk"
  
 }
 else{
  history.push('/Login');  
 }
  },[cartItems])

  
  

 
 
  
  return (
    <>
      <Paper className={classes.root} style={{}}>
      {/* <h1 style={{color:"red"}}> hshshsahhdfahdsajhsdfagh</h1> */}
      {width<500?
              <img src={image} 
// style={{height:80,width:width<500?300:400}}
style={{height:width<500?70:100,width:width<500?305:350,marginLeft:"5%"}}
 ></img>
 :<></>}
        <div style={{ padding:width<500?"0.5em": '0.5em',width:width<500?"90%":"40%",marginLeft:width<500?"5%":"30%"}}>


<GridContainer style={{ }}>
<GridItem xs={12} sm={6} md={12} lg={12} style={{display:"flex",}}>
<p style={{alignSelf:"center",fontWeight:"bold",textAlign:"left",fontSize:20}}>Add New Client</p>
</GridItem>
<GridItem xs={12} sm={6} md={12} lg={12} style={{}}>
<TextField required id="" block
                  fontColor="red"
                  style={{borderColor:"#280508"}}
                  fullWidth={true}
                  
                  label="Client Title"
                  value={client_title}
                  onChange={(e)=>{
                    setclient_title(e.target.value)
                  }} 
                  
                  />
      </GridItem>
<GridItem xs={12} sm={6} md={12} lg={12} style={{}}>
<TextField id="" block
                  fontColor="red"
                  style={{borderColor:"#280508"}}
                  fullWidth={true}
                  
                  label="Person Name"
                  value={person_name}
                  onChange={(e)=>{
                    setperson_name(e.target.value)
                  }} 
                  
                  />
      </GridItem>
      <GridItem xs={12} sm={6} md={12} lg={12} style={{}}>
<TextField  required  id="" block
                  fontColor="red"
                  style={{borderColor:"#280508"}}
                  fullWidth={true}
                  label="House No"
                  value={houseno}
                  onChange={(e)=>{
                    setHouseNo(e.target.value)
                  }} 
                  
                  />
      </GridItem>
      <GridItem xs={12} sm={6} md={12} lg={12} style={{}}>
<TextField  id="" block
                  fontColor="red"
                  style={{borderColor:"#280508"}}
                  fullWidth={true}
                  label="Address 1"
                  value={address_1}
                  onChange={(e)=>{
                    setaddress_1(e.target.value)
                  }} 
                  
                  />
      </GridItem>
      <GridItem xs={12} sm={6} md={12} lg={12} style={{}}>
<TextField  id="" block
                  fontColor="red"
                  style={{borderColor:"#280508"}}
                  fullWidth={true}
                  label="Address 2"
                  value={address_2}
                  onChange={(e)=>{
                    setaddress_2(e.target.value)
                  }} 
                  
                  />
      </GridItem><GridItem xs={12} sm={6} md={12} lg={12} style={{}}>
<TextField  id="" block
                  fontColor="red"
                  style={{borderColor:"#280508"}}
                  fullWidth={true}
                  label="City"
                  value={city}
                  onChange={(e)=>{
                    setcity(e.target.value)
                  }} 
                  
                  />
      </GridItem><GridItem xs={12} sm={6} md={12} lg={12} style={{}}>
<TextField  id="" block
                  fontColor="red"
                  style={{borderColor:"#280508"}}
                  fullWidth={true}
                  label="State"
                  value={state}
                  onChange={(e)=>{
                    setstate(e.target.value)
                  }} 
                  
                  />
      </GridItem><GridItem xs={12} sm={6} md={12} lg={12} style={{}}>
<TextField id="" block
                  fontColor="red"
                  style={{borderColor:"#280508"}}
                  fullWidth={true}
                  label="Zip"
                  value={zip}
                  onChange={(e)=>{
                    setzip(e.target.value)
                  }} 
                  
                  />
      </GridItem><GridItem xs={12} sm={6} md={12} lg={12} style={{}}>
<TextField  id="" block
                  fontColor="red"
                  style={{borderColor:"#280508"}}
                  fullWidth={true}
                  label="Phone"
                  value={phone}
                  onChange={(e)=>{
                    setphone(e.target.value)
                  }} 
                  
                  />
      </GridItem>
      <GridItem xs={12} sm={6} md={12} lg={12} style={{}}>
<TextField  id="" block
                  fontColor="red"
                  style={{borderColor:"#280508"}}
                  fullWidth={true}
                  label="Email"
                  value={email}
                  onChange={(e)=>{
                    setEmail(e.target.value)
                  }} 
                  
                  />
      </GridItem>
      <GridItem xs={12} sm={6} md={12} lg={12} style={{}}>

      <Button color="#196c90" size="lg" style={{backgroundColor:'#cb1a2c',width:width<500?"45%":"40%",marginLeft:width<500?"0%":"5%", marginTop:"5%",color:"white",borderRadius:width<500?10:20}}
                     onClick={() => {
                     
                             
                      history.push('/Home');              

                          

                
                      }}
                    >
               Back to Home
                    </Button>

      <Button color="#196c90" size="lg" style={{backgroundColor:'#cb1a2c',width:width<500?"45%":"40%",marginLeft:"5%", marginTop:"5%",color:"white",borderRadius:width<500?10:20}}
                     onClick={() => {
                      if(client_title.trim()==''){
                        // setClassicModal(true)
                        // setServerResponse('Please Enter Password !!')
                        alert("Please Enter Client Title !")
                    
                        return
                    
                       }
                      if(houseno.trim()==''){
                        // setClassicModal(true)
                        // setServerResponse('Please Enter Password !!')
                        alert("Please Enter House No  !")
                    
                        return
                    
                       }
                      //  if(address_1.trim()==''){
                      //   // setClassicModal(true)
                      //   // setServerResponse('Please Enter Password !!')
                      //   alert("Please Enter Address 1 Its mandatory !")
                    
                      //   return
                    
                      //  }
                      //  if(city.trim()==''){
                      //   // setClassicModal(true)
                      //   // setServerResponse('Please Enter Password !!')
                      //   alert("Please Enter City !")
                    
                      //   return
                    
                      //  }
                      //  if(state.trim()==''){
                      //   // setClassicModal(true)
                      //   // setServerResponse('Please Enter Password !!')
                      //   alert("Please Enter State !")
                    
                      //   return
                    
                      //  }
                      //  if(zip.trim()==''){
                      //   // setClassicModal(true)
                      //   // setServerResponse('Please Enter Password !!')
                      //   alert("Please Enter Zip !")
                    
                      //   return
                    
                      //  }
                      //  if(phone.trim()==''){
                      //   // setClassicModal(true)
                      //   // setServerResponse('Please Enter Login Name !!')
                      //  alert("Please Enter Phone  !")
                      //   return
                    
                      //  }
                      //  if(email.trim()==''){
                      //   // setClassicModal(true)
                      //   // setServerResponse('Please Enter Login Name !!')
                      //  alert("Please Enter Email !")
                      //   return
                    
                      //  }
                         if(email.trim()==''){
                          console.log()
                         }else{
                       const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                       if (!emailRegex.test(email)) {
                         alert("Please enter a valid email address must be @");
                         return
                       } 
                      }
                     
                     const obj={
                      client_id:"",
                      client_title:client_title,
                      person_name:person_name,
                      house_no:houseno,
                      address_1:address_1,
                      address_2:address_2,
                      city:city,
                      state:state,
                      zip:zip,
                      phone:phone,
                      email:email,
                      user_id:localStorage.getItem('user_id')
                     }
                     console.log(obj)
                      fetch('https://4dserver.jtelwholesale.com/4DACTION/WebClientsCreateOrUpdate_api',{

                    
                      method: 'POST',
                        'Content-Type':"application/json",
                        body: JSON.stringify(obj)
                      }).then((res)=>res.json())
                      .then( async(response)=>{
                        console.log(JSON.stringify(response))
                     

                             if(response.transaction_status=="Successful"){
                              alert("Client Added Successfully "+response.client_save_id)
                              history.push('/Home');  
                             }
                             else{
                              alert("Failed Try Again ")
                              history.push('/Home');  


                             }
                                  

                          


                      })
                
                      }}
                    >
                   Add New Client
                    </Button>
                    </GridItem>
           </GridContainer>

        </div>
        
       
       


      </Paper>
     

      {/* for prev invoice selection */}
     

    </>
  );
}
