import React, { useEffect, Fragment,useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
//24-march-2023 for acs desc
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Autocomplete from "@material-ui/lab/Autocomplete";
import MaterialTable from "material-table";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import useWindowDimensions from "../../useWindowDimensions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from "@material-ui/core/Checkbox";
import DeleteIcon from '@material-ui/icons/Delete';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import MenuItem from '@material-ui/core/MenuItem';
import RemoveRedEyeOutlinedIcon from '@material-ui//icons/RemoveRedEyeOutlined';
import image from "../../images/new/JTEL Header Block.jpg"


// import Grid from "@material-ui/core/Grid";


import HelpIcon from '@material-ui/icons/Help';
import DescriptionIcon from '@material-ui/icons/Description';

import { isMobile } from 'react-device-detect';
import TableRow from '@material-ui/core/TableRow';
import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp';
import { NavLink } from "react-router-dom";
import { Link,useHistory } from "react-router-dom";



import Button from '@material-ui/core/Button';
import Datetime from 'react-datetime'
import ReactLoading from 'react-loading';
// import Invoice from './PDF/Invoice.js'


import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";

import Slide from "@material-ui/core/Slide";

import { PDFViewer } from '@react-pdf/renderer'

import { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import SearchIcon from '@material-ui/icons/Search';

import Select from '@material-ui/core/Select';

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import DateFnsUtils from '@date-io/date-fns';
import { Html5QrcodeScanner } from 'html5-qrcode';
import CropFreeIcon from '@material-ui/icons/CropFree';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import AutorenewIcon from '@material-ui/icons/Autorenew';
import { GridList } from '@material-ui/core';
// import CovidTemplate from './Covid_PDF/CovidTemplate'
// import TemplateCertificate from './TemplateCertificate/TemplateCertificate'
import GroupIcon from '@material-ui/icons/Group';
// import CertificateTemplate from './Certificate/CertificateTemplate'

// import SignCovidTemplate from './Sign_Covid_PDF/SignCovidTemplate'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

// import Covid_AntiGen from './Covid_AntiGen/CovidTemplate'
import { bool } from 'prop-types';
import { ProductContex } from '../../ContextStore.js';


import TextField from '@material-ui/core/TextField'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
var id = localStorage.getItem('patient_name') + ""





const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 1000,
  },
});
function msToTime(ms) {
  var seconds = (ms / 1000);
  var minutes = parseInt(seconds / 60, 10);
  seconds = seconds % 60;
  var hours = parseInt(minutes / 60, 10);
  minutes = minutes % 60;

  return hours + ':' + minutes;
}

export default function Lab_Tests({}) {
  const {dispatcchUserEvents,billAmount,cartItems,TotalAmount,TotalDiscount,TotalPayable,TotalPayableAddDisc,TotalRfdAmount,TotalRfdPayable,TotalRfdPayableAddDisc,TotalRefundDiscountAmount} = useContext(ProductContex);
  var from = new Date();

  const classes = useStyles();
  const { width } = useWindowDimensions();

  const [CategoriesList, setCategoriesList] = React.useState([])
  const [DoctorNameList, setDoctorNameList] = React.useState([])
  const [ProductList, setProductList] = React.useState([])
  const [SelectedProducts, setSelectedProducts] = React.useState([])
  const [remarks,setremarks]=React.useState("")
  const [specialDiscountPer,setSpecialDiscountPer]=React.useState(0)
  const [specialDiscountValue,setSpecialDiscountValue]=React.useState(0)
  // for refund special discount
  const [rfdSpecialDiscountPer,setrfdSpecialDiscountPer]=React.useState(0)
  const [rfdSpecialDiscountValue,setrfdSpecialDiscountValue]=React.useState(0)
  //for count data 
  const [count,setcount]=React.useState(1)
  // dialoge box
  const [DialogBoxOpen,setDialogBoxOpen]=React.useState(false)
  //client list 
  const [ClientList,setClientList]=React.useState([])
  // this is for client selection
  const [open,setOpen]=React.useState(false)
   // this is for Previous Invoice Selection
   const [openPreInvoice,setopenPreInvoice]=React.useState(true)
  const [fromdate, setFromDate] = React.useState(from)
  const [todate, setToDate] = React.useState(from)
  const [ifPeriodApplyYes, setifPeriodApplyYes]= React.useState(true)
  const [ifPeriodApplyNo, setifPeriodApplyNo]= React.useState(false)
  const [PreInvoiceData, setPreInvoiceData]= React.useState([])
  // for other variable
  const [invoiceId,setInvoiceId]=React.useState("")
  const [branchId,setbranchId]=React.useState("")
  const [invoiceDate,setinvoiceDate]=React.useState("")

  const [clientId,setclientId]=React.useState("")
  const [clientTitle,setclientTitle]=React.useState("")

  const [noRecordFound,setnoRecordFound]=React.useState(false)
  // for product list autocomplete jab tak prev invoice select na ho ye na chaly
  const [ProductListempty, setProductListempty] = React.useState([{
    item_name:"Please Select Previous Invoice ! "
  }])
  const [loading,setLoading]=React.useState(false)
  const [openImageView,setopenImageView]=React.useState(false)
  const [systemId,setsystemId]=React.useState()
  const [selectedReport,setSelectedReport]=React.useState()
  const [imageloading,setimageloading]=React.useState(false)
  // for qrCode
  const [scanResult,setscanResult]=React.useState(null)
  const [openQrCodeModal,setopenQrCodeModal]=React.useState(false)

const [showQrScanner,setshowQrScanner]=React.useState(false)

  const history = useHistory();
  var tesTmg= "data:image/png;base64,";




  useEffect(()=>{
console.log("yess")
if(localStorage.getItem('user_id')  ){
  // window.location.href="https://mpl-labs.pk"
  
 }
 else{
  history.push('/Login');  
 }
  },[cartItems])

  const [columns, setColumns] = React.useState([
    { title: 'SN', field: 'sn', editable: 'never',
    render: rowData => {
      return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.sn}</p>
    }
  
  },
  //   { title: 'Code', field: 'code', editable: 'never',
  //   render: rowData => {
  //     return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.code}</p>
  //   }
  // },
  
   
  // Show detail of test 17-dec-2022
    // this field comment before 17-dec
    { title: 'Item Name', field: 'itemName',editable: 'never',
    render: rowData => {
      return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.itemName}</p>
    }
    }, 
    // { title: 'DETAILSB', field: 'detail' }, // ye button k lye lgaya tha
  
    { title: 'Rate $', field: 'rate', type: 'numeric', editable: 'never',
    render: rowData => {
      return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.rate}</p>
    } },
    {
      title: 'Qty', field: 'qty', type: 'numeric', 
      render: rowData => {
        return <p style={{ color: "#cb1a2d", fontWeight: "bold" }}>{rowData.qty}</p>
      }
    },
    {
      title: "Rfd", field: 'rfd', type: 'numeric' ,
      //  editable: selectedValue.includes('Normal') ? "onUpdate" : "never",
       render: rowData => {
        return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.rfd}</p>
      }
    },
    {
      title: 'Amount $', field: 'amount', type: 'numeric', editable: 'never', 
      render: rowData => {
        return <p style={{ color: "#cb1a2d", fontWeight: "bold" }}>{rowData.amount}</p>
      }
    },
    {
      title: 'Disc %', field: 'disc', type: 'numeric', 
       render: rowData => {
        return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.disc}</p>
      }
    },
    {
      title: 'Discount $', field: 'discount', type: 'numeric', editable: 'never',
       render: rowData => {
        return <p style={{ color: "#cb1a2d", fontWeight: "bold" }}>{rowData.discount}</p>
      }
    },
    {
      title: 'Net Val $', field: 'netvalue', type: 'numeric', editable: 'never',
       render: rowData => {
        return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.netvalue}</p>
      }
    }
  ]);
  // cond lgae hai ager login pe if discount true hai to disc column edit ho sky
  const [columnsNewForCondiotion, setcolumnsNewForCondiotion] = React.useState([
    { title: 'SN', field: 'sn', editable: 'never',
    render: rowData => {
      return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.sn}</p>
    }
  
  },
  //   { title: 'Code', field: 'code', editable: 'never',
  //   render: rowData => {
  //     return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.code}</p>
  //   }
  // },
  
   
  // Show detail of test 17-dec-2022
    // this field comment before 17-dec
    { title: 'Item Name', field: 'itemName',editable: 'never',
    render: rowData => {
      return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.itemName}</p>
    }
    }, 
    // { title: 'DETAILSB', field: 'detail' }, // ye button k lye lgaya tha
  
    { title: 'Rate $', field: 'rate', type: 'numeric', editable: 'never',
    render: rowData => {
      return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.rate}</p>
    } },
    {
      title: 'Qty', field: 'qty', type: 'numeric', 
      render: rowData => {
        return <p style={{ color: "#cb1a2d", fontWeight: "bold" }}>{rowData.qty}</p>
      }
    },
    {
      title: "Rfd", field: 'rfd', type: 'numeric' ,
      //  editable: selectedValue.includes('Normal') ? "onUpdate" : "never",
       render: rowData => {
        return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.rfd}</p>
      }
    },
    {
      title: 'Amount $', field: 'amount', type: 'numeric', editable: 'never', 
      render: rowData => {
        return <p style={{ color: "#cb1a2d", fontWeight: "bold" }}>{rowData.amount}</p>
      }
    },
    {
      title: 'Disc %', field: 'disc', type: 'numeric', editable: 'never',
       render: rowData => {
        return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.disc}</p>
      }
    },
    {
      title: 'Discount $', field: 'discount', type: 'numeric', editable: 'never',
       render: rowData => {
        return <p style={{ color: "#cb1a2d", fontWeight: "bold" }}>{rowData.discount}</p>
      }
    },
    {
      title: 'Net Val $', field: 'netvalue', type: 'numeric', editable: 'never',
       render: rowData => {
        return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.netvalue}</p>
      }
    }
  ]);
  const [data, setdata] = React.useState([
    {
    sn:1,
    code:"ABC",
    itemName:"abc",
    rate:20,
    qty:10,
    rfd:10,
    amount:100,
    discount:100,
    netvalue:100
  }])
    // QrCode 7-oct-2024
  //   useEffect(()=>{
  //     //for qr code
  //  const scanner =new Html5QrcodeScanner ('reader',{
  //   qrbox:{
  //     width:350,
  //     height:350
  //   },
  //   fps:5,
  // })
  // scanner.render(success,error)
  
  // function success(result){
  //   scanner.clear();
  //   setscanResult(result)
  //   setopenQrCodeModal(true)
  //   console.log(result)
  
  // }
  // function error(err){
  //   // scanner.clear();
  //   // console.log("error in scanner 531"+err)
  
  // }
      
  //       },[openQrCodeModal])

  useEffect(()=>{
dispatcchUserEvents("ClearData")

    // fetch("https://4dserver.jtelwholesale.com/4DACTION/WebProductsCategoriesCallingUp",
    fetch("https://4dserver.jtelwholesale.com/4DACTION/WebProductsCategoriesCallingUp",

    {
      method:"POST",
      'Content-Type':"application/json",
      body: JSON.stringify({
        // branch_id:1,
        branch_id:parseInt(localStorage.getItem('branch_id')),

        user_id:localStorage.getItem('user_id')
      })
    }).then((res) => res.json()).then((response) => {
    // console.log("check response "+JSON.stringify(response))  
    setCategoriesList(response)
    //   var obj = {}
    //   response.map((item) => {
    //     obj[item.DoctorID] = item.DoctorName
    //   })
      // setDoctorNameList(obj)

    })
    // fetch("https://4dserver.jtelwholesale.com/4DACTION/WebClientsGenListCallingUp_api",
    fetch("https://4dserver.jtelwholesale.com/4DACTION/WebClientsGenListCallingUp_api",

    {
      method:"POST",
      'Content-Type':"application/json",
      body: JSON.stringify({
        // branch_id:1,
        branch_id:parseInt(localStorage.getItem('branch_id')),

        user_id:localStorage.getItem('user_id')

      })
    }).then((res) => res.json()).then((response) => {
    // console.log("check Clients data "+JSON.stringify(response))  
    // setClientList(response)
    var data = [];
    response.map((item) => {
      // console.log(item)
     
      var obj = {
        clientTitle: item.arr_cl_name,
        phone: item.arr_phone,
        city: item.arr_city,
        state: item.arr_state,
        zip: item.arr_zip,
        address: item.arr_address,

        select: (
          <Button
            variant="contained"
            color="primary"
            style={{
            backgroundColor:"#cb1a2d"

            }}
            className={classes.button}
            onClick={() => {
              setclientId(item.arr_cl_id)
              setclientTitle(item.arr_cl_name)
              // localStorage.setItem('client_id',item.arr_cl_id)
              // localStorage.setItem('client_Title',item.arr_cl_name)
          // setId(item.Patient_ID)
          // setName(item.Patient_Name)
          // setMobile(item.MobileNo)
          setOpen(false)
            }}
          >
            Select
          </Button>
        ),
      };

      data.push(obj);
    });
    setClientList(data);
    // setOpen(true);
    // setCategoriesList(response)
    //   var obj = {}
    //   response.map((item) => {
    //     obj[item.DoctorID] = item.DoctorName
    //   })
      // setDoctorNameList(obj)

    })
    // fetch("https://4dserver.jtelwholesale.com/4DACTION/WebProductsGenCallingUp_api",
    fetch("https://4dserver.jtelwholesale.com/4DACTION/WebProductsGenCallingUp_api",

    {
      method:"POST",
      'Content-Type':"application/json",
      body: JSON.stringify({
        // branch_id:1,
        branch_id:parseInt(localStorage.getItem('branch_id')),

        category_id:0,
        user_id:localStorage.getItem('user_id')

      })
    }).then((res) => res.json()).then((response) => {
    // console.log("check Products response "+JSON.stringify(response))  
    setProductList(response)
    //   var obj = {}
    //   response.map((item) => {
    //     obj[item.DoctorID] = item.DoctorName
    //   })
      // setDoctorNameList(obj)

    }).catch((err)=>{
      console.log("err "+err)

     alert("Session Expired "+err)
     localStorage.clear()
//       if(err.includes("Invalid User ID")){
// localStorage.clear()

//       }
    })
    var fromNew =new Date()
    var to = new Date();
    to.setDate(to.getDate() - 2);


   
  },[])
 
  
  return (
    <>
      <Paper className={classes.root} style={{}}>
      {/* <h1 style={{color:"red"}}> hshshsahhdfahdsajhsdfagh</h1> */}
      {/* <div id="reader"></div> */}

      {width<500?
              <img src={image} 
// style={{height:80,width:width<500?300:400}}
style={{height:width<500?70:100,width:width<500?305:350,marginLeft:"5%"}}
 ></img>
 :<></>}
        <div style={{ padding: '1em'}}>
<GridContainer>
<GridItem xs={12} sm={12} md={12} lg={12} style={{marginTop:width<960?"2%":"0%"}}>
            {/* <Button
           variant="contained"
           color="primary"
           onClick={() => {
       
        //  alert("hy")
        // setDialogBoxOpen(true)
        setopenPreInvoice(true)
            
           }}
   
           // disabled={saveInvoice}
           style={{
             textAlign: 'center',
             width: "15.5em",
            //  padding: "1em",
            //  height: "4em",
             marginLeft:"2%",
            //  marginLeft: "8px",
            //  marginTop:25,
            //  marginBottom:10
            backgroundColor:"#cb1a2d"

           }}>
            

<DescriptionIcon style={{width:20,height:20,color:"white",marginRight:5}}></DescriptionIcon>
               Select Prev Invoice
           </Button> */}
           {clientTitle&&<>
            <Button
           variant="contained"
           color="primary"
           onClick={() => {
       
        //  alert("hy")
        // setDialogBoxOpen(true)
        setOpen(true)
            
           }}
   
           // disabled={saveInvoice}
           style={{
             textAlign: 'center',
             width: "15.5em",
           
             marginLeft:"2%",
           
             marginTop:width<500?5:0,
            //  marginBottom:10
            backgroundColor:"#cb1a2d"

           }}>
<GroupIcon style={{width:20,height:20,color:"white",marginRight:5}}></GroupIcon>
               Select Clients
           </Button>
           
           <p style={{fontWeight:"bold",marginLeft:"2%"}}>
            Client :{clientTitle}
          </p>
            
            </>}
            </GridItem>
</GridContainer>
        
          {/* <h3 style={{color:"black",fontWeight:"bold",marginLeft:"1%"}} >
 
   Invoice Update
   
              </h3> */}
           
          <GridContainer style={{marginTop:width<960?"2%":"2%"}}>
            <GridItem xs={12} sm={12} md={4} style={{marginLeft:width<500?"0%":"1%"}}>

         {invoiceId ?
            <Autocomplete
                  id="Ref_By_Doctor"
                  
                  options={ProductList}
                  classes={{
                    option: classes.option
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option.item_name + ""}
                  style={{
                    // width: "30em",
                    marginRight: '1em',
                    marginLeft:width<500?"0em ":width<960?"4px": '0em',

                    
                  }}
                  // renderOption={(option) => <React.Fragment>{option.DoctorName}</React.Fragment>}
                  onChange={async(event, newValue) => {
                    console.log("check cartItem"+cartItems)

                    if (newValue != null) {

                      // setRef_By_Dr(newValue.DoctorID)
                      console.log("Selected Product"+JSON.stringify(newValue))
                      // setSelectedProducts(newValue)
                     
                      var obj1 =
                  {
                    sn:count,
                    systemId: newValue.system_id,
                    code: newValue.item_code,
                    itemName: newValue.item_name,
                    rate:newValue.selling_price,
                    qty:1,
                    rfd:0,
                    rfdAmount:0,
                    amount:newValue.selling_price,
                    disc:0,
                    discount:0,
                    netvalue:newValue.selling_price,

                    buyingPrice:newValue.buying_price,
                     
                  }
                  
                  // var all_test = [...SelectedProducts, obj1]
                  // var hold = await all_test.map((row) => {

                  // })
//                   Promise.all(all_test).then((values) => {
//                     setProductList(values)
// console.log("chekc one by one "+JSON.stringify(values))
//                   })


console.log("check obj"+JSON.stringify(obj1))
dispatcchUserEvents("ADD_To_CART",{data:obj1,requestedQty:1})
setcount(count+1)


                      
                      // setRef_No('')
                    } else {
                      // setRef_By_Dr('')
                      // setRef_No('')
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Products"
                      variant="outlined"
                    
                      style={{
                        // width: "30em"
                      }}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password" // disable autocomplete and autofill
                      }}
                    />
                  )}
                /> 
                :
                  <Autocomplete
                  id="Ref_By_Doctor"
                  
                  options={ProductListempty}
                  classes={{
                    option: classes.option
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option.item_name + ""}
                  style={{
                    // width: "30em",
                    marginRight: '1em',
                    marginLeft:width<500?"0em ":width<960?"4px": '0em',

                    
                  }}
                  // renderOption={(option) => <React.Fragment>{option.DoctorName}</React.Fragment>}
                  // onChange={async(event, newValue) => {
       
                  // }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Products"
                      variant="outlined"
                    disabled
                      style={{
                        // width: "30em"
                      }}
                      // inputProps={{
                      //   ...params.inputProps,
                      //   autoComplete: "new-password" // disable autocomplete and autofill
                      // }}
                    />
                  )}
                />
                    }
            </GridItem>
            <GridItem xs={12} sm={12} md={4} style={{marginTop:width<960?"2%":"0%"}}>
              {invoiceId?
            <Autocomplete
                  id="Ref_By_Doctor"

                  options={CategoriesList}
                  classes={{
                    option: classes.option
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option.cat_title + ""}
                  style={{
                    // width: "30em",
                    marginRight: '1em'
                  }}
                  // renderOption={(option) => <React.Fragment>{option.DoctorName}</React.Fragment>}
                  onChange={(event, newValue) => {
                    setProductList([])

                    if (newValue != null) {

                      // setRef_By_Dr(newValue.DoctorID)
                      console.log("Ref by Doctor self"+newValue.cat_id)
                      // fetch("https://4dserver.jtelwholesale.com/4DACTION/WebProductsGenCallingUp_api",
                      fetch("https://4dserver.jtelwholesale.com/4DACTION/WebProductsGenCallingUp_api",

    {
      method:"POST",
      'Content-Type':"application/json",
      body: JSON.stringify({
        // branch_id:1,
        branch_id:parseInt(localStorage.getItem('branch_id')),

        category_id:newValue.cat_id,
        user_id:localStorage.getItem('user_id')

      })
    }).then((res) => res.json()).then((response) => {
    console.log("check Products response "+JSON.stringify(response))  
    setProductList(response)
    //   var obj = {}
    //   response.map((item) => {
    //     obj[item.DoctorID] = item.DoctorName
    //   })
      // setDoctorNameList(obj)

    })

                      // setRef_No('')
                    } else {
                      // setRef_By_Dr('')
                      // setRef_No('')
                      fetch("https://4dserver.jtelwholesale.com/4DACTION/WebProductsGenCallingUp_api",

                      {
                        method:"POST",
                        'Content-Type':"application/json",
                        body: JSON.stringify({
                          // branch_id:1,
                          branch_id:parseInt(localStorage.getItem('branch_id')),
                  
                          category_id:0,
                          user_id:localStorage.getItem('user_id')
                  
                        })
                      }).then((res) => res.json()).then((response) => {
                      console.log("check Products response "+JSON.stringify(response))  
                      setProductList(response)
                      //   var obj = {}
                      //   response.map((item) => {
                      //     obj[item.DoctorID] = item.DoctorName
                      //   })
                        // setDoctorNameList(obj)
                  
                      })
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Categories"
                      variant="outlined"
                      style={{
                        // width: "30em"
                      }}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password" // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
           :
           <Autocomplete
           id="Ref_By_Doctor"
           
           options={ProductListempty}
           classes={{
             option: classes.option
           }}
           autoHighlight
           getOptionLabel={(option) => option.item_name + ""}
           style={{
             // width: "30em",
             marginRight: '1em',
             marginLeft:width<500?"0em ":width<960?"4px": '0em',

             
           }}
           // renderOption={(option) => <React.Fragment>{option.DoctorName}</React.Fragment>}
           // onChange={async(event, newValue) => {

           // }}
           renderInput={(params) => (
             <TextField
               {...params}
               label="Select Categories"

               variant="outlined"
             disabled
               style={{
                 // width: "30em"
               }}
               // inputProps={{
               //   ...params.inputProps,
               //   autoComplete: "new-password" // disable autocomplete and autofill
               // }}
             />
           )}
         />}
            
            </GridItem>
            {/* <GridItem xs={12} sm={12} md={3} style={{marginTop:width<960?"2%":"0%"}}>
            <Button
           variant="contained"
           color="primary"
           onClick={() => {
       
        //  alert("hy")
        // setDialogBoxOpen(true)
        setopenPreInvoice(true)
            
           }}
   
           // disabled={saveInvoice}
           style={{
             textAlign: 'center',
             width: "15.5em",
            //  padding: "1em",
            //  height: "4em",
             marginLeft:"2%",
            //  marginLeft: "8px",
            //  marginTop:25,
            //  marginBottom:10
            backgroundColor:"#cb1a2d"

           }}>
            

<DescriptionIcon style={{width:20,height:20,color:"white",marginRight:5}}></DescriptionIcon>
               Select Prev Invoice
           </Button>
           {clientTitle&&<>
            <Button
           variant="contained"
           color="primary"
           onClick={() => {
       
        //  alert("hy")
        // setDialogBoxOpen(true)
        setOpen(true)
            
           }}
   
           // disabled={saveInvoice}
           style={{
             textAlign: 'center',
             width: "15.5em",
           
             marginLeft:"2%",
           
             marginTop:5,
            //  marginBottom:10
            backgroundColor:"#cb1a2d"

           }}>
<GroupIcon style={{width:20,height:20,color:"white",marginRight:5}}></GroupIcon>
               Select Clients
           </Button>
           
           <p style={{fontWeight:"bold",marginLeft:"2%"}}>
            Client :{clientTitle}
          </p>
            
            </>}
            </GridItem> */}
            <GridItem xs={12} sm={12} md={12} lg={12} style={{marginTop:"2%"}}>
              {width>4000?
              <MaterialTable
                title="Selected Products"
                // columns={columns}
                columns={localStorage.getItem('If_allow_discount')=="true"?columns:columnsNewForCondiotion}

                data={cartItems}
                style={{
                  marginLeft:width<960?0: 10
                }}
                options={{
                  // paging: false,
                  headerStyle: {
                    backgroundColor: "#5794ca",
                    color: "#FFF",
                    // fontSize: "17px",
                    // textAlign: "center",
                    fontWeight: "bold",
                    // minHeight:"50em"
                  },
                
                  rowStyle: rowData => {
                    // if(rowData.tableData.isTreeExpanded === false && rowData.tableData.path.length === 1) {
                    //   return {};
                    // }
                    // const rowBackgroundColor = constPathColors[rowData.tableData.path.length];
                    return {backgroundColor:rowData.rfd>0? "#ffffe0":"",
                  };
                  }
                }}
                maxBodyHeight={"50em"}
                // actions={[
                //   {
                //       icon: "save",
                //       tooltip: "Save User",
                //       onClick: (event, rowData) => alert("You saved " + rowData.name)
                //   }
                //   ]}
                editable={{
                  onRowUpdate: ( newData, oldData) =>
                    new Promise((resolve, reject) => {
                      // setTimeout(async () => {
                        console.log("old data"+JSON.stringify(oldData))
                        console.log("new data"+JSON.stringify(newData))
                        const index = oldData.systemId;
                        if(oldData.qty==0&&oldData.rfd>0){
                        console.log("check oper rfd"+newData.rfd+"-"+"qty"+newData.qty)

                          newData.rfd=0
                        }
                        if(newData.rfd>0 &&newData.qty!=0){
                        console.log("check niche"+newData.rfd+"-"+"qty"+newData.qty)

                        newData.qty=0
                        // console.log("check rfd"+newData.rfd+"-"+"qty"+newData.qty)
                      }
                      //  if(newData.rfd>0&& newData.qty>0){
                      //   newData.rfd=0
                      // }
                        console.log("index is "+index)
                        const dataUpdate = [...cartItems];
                        console.log("New data is "+JSON.stringify(newData.rfd))
                        resolve()
            dispatcchUserEvents("PERCENTAGEUPDATE",{itemIndex:index,qty:newData.qty,rfd:newData.rfd,updatedAmount:(newData.qty*newData.rate).toFixed(2),disc:newData.disc,updatedRfdAmount:(newData.rfd*newData.rate).toFixed(2)})

            dispatcchUserEvents("INCREASE_QTY",{itemIndex:index,qty:newData.qty,updatedAmount:(newData.qty*newData.rate).toFixed(2),rfd:newData.rfd,updatedRfdAmount:(newData.rfd*newData.rate).toFixed(2)})
          // 19-jan-2024 add kiya tha ku k editable se phly agr special disc apply kiya tha to dobara kr den 
            setSpecialDiscountPer(0)
            setSpecialDiscountValue(0)
            
            resolve()
            this.forceUpdate();
            
                        resolve()
                     
                        // reject()
                       

                        // const index = oldData.systemId;

            // dispatcchUserEvents("INCREASE_QTY",{itemIndex:index,qty:newData.qty,updatedAmount:(newData.qty*newData.amount)})

                      //   const dataUpdate = [...data];
                      //   const index = oldData.tableData.id;
                      //   if (oldData.title != newData.title && (oldData.Test_ID.includes('SRV'))) {
                      //     dataUpdate[index].title = newData.title;
                      //     setData([...dataUpdate]);
                      //   }
                      //  // Update Require
                      //   if(newData.discount_percent>newData.AllowDiscountPercent){
                      //     alert("Discount Limit of "+newData.Test_Name+" = "+newData.AllowDiscountPercent+"%")
                      //     resolve();
                      //     return
                      //   }
                        
                      //   if (newData.discount_percent != oldData.discount_percent) {
                      //     if (newData.discount_percent > 100) {

                      //     } else {
                      //       var dc_value = (newData.cost / 100) * parseInt(newData.discount_percent)

                      //       var net_cost = (newData.cost - dc_value) - parseInt(newData.special_discount)

                      //       if (net_cost < 0) {
                      //         alert("More Disocunt can't applied!")

                      //         resolve();
                      //         return
                      //       }

                      //       newData.discount_value = dc_value
                      //       newData.net_cost = net_cost

                      //       dataUpdate[index] = newData;
                      //       console.log("2911"+JSON.stringify(dataUpdate[index]))
                      //       console.log("2911"+JSON.stringify( newData))
                      //        setData([...dataUpdate]);
                      //     }
                      //   }

                      //   var cost = 0
                      //   var discount_value = 0
                      //   var sp_disc = 0
                      //   var net_cost = 0
                      //   var temp = await dataUpdate.map((item) => {
                      //     console.log("2920"+item)
                      //     cost = cost + item.cost
                      //     discount_value = discount_value + item.discount_value
                      //     sp_disc = sp_disc + item.special_discount
                      //     return net_cost = net_cost + item.net_cost
                      //   })
                      //   Promise.all(temp).then(() => {
                      //     setTotal_cost(cost)
                      //     setTotalDiscountValue(discount_value)
                      //     setTotalSpecialDiscount(sp_disc)
                      //     setTotalNetCost(net_cost)
                      //     setCC([])
                      //     setBalance_Payment(net_cost)
                      //     setReceived_Payment(0)
                      //   })




                        resolve();
                      // }, 1000)
                    }),
                  onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                      setTimeout(async () => {
                        // const index = oldData.systemId;
                        let index = cartItems.findIndex(x => x.systemId == oldData.systemId);

                        console.log("check delete data"+JSON.stringify(oldData))
                        dispatcchUserEvents("REMOVE_FROM_CART",{data:oldData,deductedAmount:oldData.qty*oldData.netvalue,index})
                        setSpecialDiscountPer(0)
                        setSpecialDiscountValue(0)
                        
                        resolve();

                      }, 1000)
                    }),
                }}
              />:
              <>
                         
                      {/* header end */}
<div style={{width:"100%",height:"100%",minHeight:250,maxHeight:250,backgroundColor:"white",border:"1px solid #ccc",borderRadius:5,display:cartItems.length==0 ?"flex":"",justifyContent:cartItems.length==0 ?"":"",overflow:"scroll",flexDirection:"column"}}>
<div style={{marginTop:0,width:width<500?"200%":"100%",backgroundColor:"#268ae1",border:"2px solid #268ae1",borderRadius:5, display:"flex",flexDirection:"row",color:"white",overflow:"auto",minHeight:60}}>
             <div style={{width:"200%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} >
           <p style={{fontWeight:"bold",color:"white",fontSize:13}}>S/n</p>
           {/* <p style={{fontWeight:"bold",color:"#268ae1"}}>  {index+1}</p> */}


           </div>
           <div style={{width:"600%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"flex-start"}} >
           <p style={{fontWeight:"bold",color:"white",fontSize:13}}>Item Name</p>
           {/* <p style={{fontWeight:"bold",color:"#000000",fontSize:width<500?15:15}}>  {item.itemName}</p> */}


           </div>
        
           <div style={{width:"400%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} >

           <p style={{fontWeight:"bold",color:"white",fontSize:13}}>Rate $</p>

           {/* <p style={{fontWeight:"bold",color:"#000000"}}> $  {item.rate.toFixed(2)} </p> */}



           </div>
           <div style={{width:"400%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} >

           <p style={{fontWeight:"bold",color:"white",fontSize:13}}>Qty</p>




           </div>
     
           {/* <div style={{width:"10%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} >


<p style={{fontWeight:"bold",color:"#77787a"}}>$ {item.rfd.toFixed(2)} </p>


</div> */}
           <div style={{width:"500%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} >

           <p style={{fontWeight:"bold",color:"white",fontSize:13}}>Amount $</p>
           {/* <p style={{fontWeight:"bold",color:"#cb1a2d"}}>$ {item.amount} </p> */}


           </div>
           <div style={{width:"400%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} >


<p style={{fontWeight:"bold",color:"white",fontSize:13}}>Rfd</p>






</div>
           <div style={{width:"400%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} >

           <p style={{fontWeight:"bold",color:"white",fontSize:13}}>Disc %</p>
{/* <p style={{fontWeight:"bold",color:"#000000"}}>$ {item.disc} </p> */}


</div>   <div style={{width:"400%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} >

<p style={{fontWeight:"bold",color:"white",fontSize:13}}>Discount </p>

{/* <p style={{fontWeight:"bold",color:"#000000"}}>$ {item.discount} </p> */}


</div>   <div style={{width:"400%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} >

<p style={{fontWeight:"bold",color:"white",fontSize:13}}>Net Val $</p>

{/* <p style={{fontWeight:"bold",color:"#000000"}}>$ {item.netvalue} </p> */}


</div>    
<div style={{width:"400%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} >

<p style={{fontWeight:"bold",color:"white",fontSize:13}}>Stock</p>

{/* <p style={{fontWeight:"bold",color:"#cb1a2d"}}>{item.stock} </p> */}


</div>   

           <div style={{width:"300%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",}} >

           <p style={{fontWeight:"bold",color:"white",fontSize:13}}>    View</p>





</div>
<div style={{width:"300%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",}} >

<p style={{fontWeight:"bold",color:"white",fontSize:13}}>    Action</p>





</div>
               </div>
               {cartItems?.map((item,index)=>{
  return(
  <div style={{marginTop:2,width:width<500?"200%":"100%",backgroundColor:"white",borderTop:"1px solid #ccc",borderBottom:"1px solid #ccc",display:"flex",flexDirection:"row",color:"white",overflow:"auto",minHeight:60}}>
             <div style={{width:"200%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} >
           {/* <p style={{fontWeight:"bold",color:"#268ae1"}}>  S/N</p> */}
           <p style={{fontWeight:"bold",color:"#268ae1"}}>  {index+1}</p>


           </div>
           <div style={{width:"600%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"flex-start"}} >
           {/* <p style={{fontWeight:"bold",color:"#268ae1"}}>  ItemName</p> */}
           <p style={{fontWeight:"bold",color:"#000000",fontSize:width<500?15:15}}>  {item.itemName}</p>


           </div>
        
           <div style={{width:"400%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} >

           {/* <p style={{fontWeight:"bold",color:"#268ae1"}}>    Price</p> */}

           <p style={{fontWeight:"bold",color:"#000000"}}> $  {item.rate.toFixed(2)} </p>



           </div>
           <div style={{width:"400%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",}} >

           {/* <p style={{fontWeight:"bold",color:"#268ae1"}}>    Qty</p> */}
           <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
           <button style={{width:width<500?22:30,backgroundColor:"#268ae1",borderLeft:"2px solid #268ae1",borderTop:"2px solid #268ae1",borderBottom:"2px solid #268ae1",borderRight:"2px solid #268ae1",borderTopLeftRadius:5,borderBottomLeftRadius:5,color:"white"}} onClick={()=>{
            let index = cartItems.findIndex(x => x.systemId == item.systemId);
           // dispatcchUserEvents("PERCENTAGEUPDATE",{itemIndex:index,qty:item.qty+1,rfd:item.rfd,updatedAmount:((item.qty+1)*item.rate).toFixed(2),disc:item.discount,updatedRfdAmount:(item.rfd*item.rate).toFixed(2)})
           console.log("index")
           
           console.log(index)
           if(item.qty<=1){
            alert("Product qty must b greater than zero ")
           }
           else{
           
          //  dispatcchUserEvents("INCREASE_QTY",{itemIndex:index,qty:item.qty-1,updatedAmount:((item.qty-1)*item.rate).toFixed(2),rfd:item.rfd,updatedRfdAmount:(item.rfd*item.rate).toFixed(2)})
          let index = cartItems.findIndex(x => x.systemId == item.systemId);
          // dispatcchUserEvents("PERCENTAGEUPDATE",{itemIndex:index,qty:item.qty+1,rfd:item.rfd,updatedAmount:((item.qty+1)*item.rate).toFixed(2),disc:item.discount,updatedRfdAmount:(item.rfd*item.rate).toFixed(2)})
          console.log("index")
          
          console.log(index)
          
          // dispatcchUserEvents("INCREASE_QTY",{itemIndex:index,qty:item.qty+1,updatedAmount:((item.qty+1)*item.rate).toFixed(2),rfd:item.rfd,updatedRfdAmount:(item.rfd*item.rate).toFixed(2)})
          // console.log("old data"+JSON.stringify(oldData))
          console.log("new data"+JSON.stringify(item))
          
          // 1-sep-2024
          if(item.qty >item.stock){
          alert(item.itemName+" Item Quantity is greater than Stock !")
          }
          
          if(item.qty==0&&item.rfd>0){
          console.log("check oper rfd"+item.rfd+"-"+"qty"+item.qty)
          
            item.rfd=0
          }
          if(item.rfd>0 &&item.qty!=0){
          console.log("check niche"+item.rfd+"-"+"qty"+item.qty)
          
          item.qty=0
          
          }
          
          console.log("index is "+index)
          const dataUpdate = [...cartItems];
          console.log("New data is "+JSON.stringify(item.rfd))
          // resolve()
          dispatcchUserEvents("PERCENTAGEUPDATE",{itemIndex:item.systemId,qty:item.qty-1,rfd:item.rfd,updatedAmount:((item.qty-1)*item.rate).toFixed(2),disc:item.disc,updatedRfdAmount:(item.rfd*item.rate).toFixed(2)})
          
          dispatcchUserEvents("INCREASE_QTY",{itemIndex:item.systemId,qty:item.qty-1,updatedAmount:((item.qty-1)*item.rate).toFixed(2),rfd:item.rfd,updatedRfdAmount:(item.rfd*item.rate).toFixed(2)})
          // 19-jan-2024 add kiya tha ku k editable se phly agr special disc apply kiya tha to dobara kr den 
          setSpecialDiscountPer(0)
          setSpecialDiscountValue(0)
        }
          }}> <p style={{}}> - </p></button>
           {/* <p style={{fontWeight:"bold",color:"#77787a"}}>    1</p> */}
           <button style={{width:width<500?25:30,backgroundColor:"white",borderBottom:"2px solid white",borderTop:"2px solid white",borderLeft:"0px solid white",borderRight:"0px solid white",}}
           >
            <p style={{marginLeft:-4}}>
            {item.qty}
            </p></button>
           
           <button style={{width:width<500?22:30,backgroundColor:"#268ae1",border:"2px solid #268ae1",borderLeft:"2px solid #268ae1",borderTopRightRadius:5,borderBottomRightRadius:5,color:"white"}} onClick={()=>{
 let index = cartItems.findIndex(x => x.systemId == item.systemId);
// dispatcchUserEvents("PERCENTAGEUPDATE",{itemIndex:index,qty:item.qty+1,rfd:item.rfd,updatedAmount:((item.qty+1)*item.rate).toFixed(2),disc:item.discount,updatedRfdAmount:(item.rfd*item.rate).toFixed(2)})
console.log("index")

console.log(index)

// dispatcchUserEvents("INCREASE_QTY",{itemIndex:index,qty:item.qty+1,updatedAmount:((item.qty+1)*item.rate).toFixed(2),rfd:item.rfd,updatedRfdAmount:(item.rfd*item.rate).toFixed(2)})
// console.log("old data"+JSON.stringify(oldData))
console.log("new data"+JSON.stringify(item))

// 1-sep-2024
if(item.qty >item.stock){
alert(item.itemName+" Item Quantity is greater than Stock !")
}

if(item.qty==0&&item.rfd>0){
console.log("check oper rfd"+item.rfd+"-"+"qty"+item.qty)

  item.rfd=0
}
if(item.rfd>0 &&item.qty!=0){
console.log("check niche"+item.rfd+"-"+"qty"+item.qty)

item.qty=0

}

console.log("index is "+index)
const dataUpdate = [...cartItems];
console.log("New data is "+JSON.stringify(item.rfd))
// resolve()
dispatcchUserEvents("PERCENTAGEUPDATE",{itemIndex:item.systemId,qty:item.qty+1,rfd:item.rfd,updatedAmount:((item.qty+1)*item.rate).toFixed(2),disc:item.disc,updatedRfdAmount:(item.rfd*item.rate).toFixed(2)})

dispatcchUserEvents("INCREASE_QTY",{itemIndex:item.systemId,qty:item.qty+1,updatedAmount:((item.qty+1)*item.rate).toFixed(2),rfd:item.rfd,updatedRfdAmount:(item.rfd*item.rate).toFixed(2)})
// 19-jan-2024 add kiya tha ku k editable se phly agr special disc apply kiya tha to dobara kr den 
setSpecialDiscountPer(0)
setSpecialDiscountValue(0)
   }}><p style={{}}>+</p></button>

           </div>



           </div>

           {/* <div style={{width:"10%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} >


<p style={{fontWeight:"bold",color:"#77787a"}}>$ {item.rfd.toFixed(2)} </p>


</div> */}
           <div style={{width:"500%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} >

           {/* <p style={{fontWeight:"bold",color:"#268ae1"}}>    Net Value</p> */}
           <p style={{fontWeight:"bold",color:"#cb1a2d"}}>$ {item.amount} </p>


           </div>
           <div style={{width:"400%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",}} >

{/* <p style={{fontWeight:"bold",color:"#268ae1"}}>    Qty</p> */}
<div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
<button style={{width:width<500?22:30,backgroundColor:"#268ae1",borderLeft:"2px solid #268ae1",borderTop:"2px solid #268ae1",borderBottom:"2px solid #268ae1",borderRight:"2px solid #268ae1",borderTopLeftRadius:5,borderBottomLeftRadius:5,color:"white"}} onClick={()=>{
//  let index = cartItems.findIndex(x => x.systemId == item.systemId);
// dispatcchUserEvents("PERCENTAGEUPDATE",{itemIndex:index,qty:item.qty+1,rfd:item.rfd,updatedAmount:((item.qty+1)*item.rate).toFixed(2),disc:item.discount,updatedRfdAmount:(item.rfd*item.rate).toFixed(2)})
console.log("index")

console.log(index)


// dispatcchUserEvents("INCREASE_QTY",{itemIndex:index,qty:item.qty-1,updatedAmount:((item.qty-1)*item.rate).toFixed(2),rfd:item.rfd,updatedRfdAmount:(item.rfd*item.rate).toFixed(2)})
// / let index = cartItems.findIndex(x => x.systemId == item.systemId);
// dispatcchUserEvents("PERCENTAGEUPDATE",{itemIndex:index,qty:item.qty+1,rfd:item.rfd,updatedAmount:((item.qty+1)*item.rate).toFixed(2),disc:item.discount,updatedRfdAmount:(item.rfd*item.rate).toFixed(2)})
// console.log("index")

// console.log(index)

// dispatcchUserEvents("INCREASE_QTY",{itemIndex:index,qty:item.qty+1,updatedAmount:((item.qty+1)*item.rate).toFixed(2),rfd:item.rfd,updatedRfdAmount:(item.rfd*item.rate).toFixed(2)})
let index = cartItems.findIndex(x => x.systemId == item.systemId);
// dispatcchUserEvents("PERCENTAGEUPDATE",{itemIndex:index,qty:item.qty+1,rfd:item.rfd,updatedAmount:((item.qty+1)*item.rate).toFixed(2),disc:item.discount,updatedRfdAmount:(item.rfd*item.rate).toFixed(2)})
console.log("index")

console.log(index)

// dispatcchUserEvents("INCREASE_QTY",{itemIndex:index,qty:item.qty+1,updatedAmount:((item.qty+1)*item.rate).toFixed(2),rfd:item.rfd,updatedRfdAmount:(item.rfd*item.rate).toFixed(2)})
// console.log("old data"+JSON.stringify(oldData))
console.log("new data"+JSON.stringify(item))

// 1-sep-2024
if(item.rfd <=1){
alert(item.itemName+" Item Refund Quantity must b greater than zero !")
return
}

if(item.qty==0&&item.rfd>0){
console.log("check oper rfd"+item.rfd+"-"+"qty"+item.qty)

  // item.rfd=0
}
if(item.rfd>0 &&item.qty!=0){
console.log("check niche"+item.rfd+"-"+"qty"+item.qty)

item.qty=0

}

console.log("index is "+index)
const dataUpdate = [...cartItems];
console.log("New data is "+JSON.stringify(item))
// resolve()
dispatcchUserEvents("PERCENTAGEUPDATE",{itemIndex:item.systemId,qty:0,rfd:(item.rfd-1),updatedAmount:((0)*item.rate).toFixed(2),disc:item.disc,updatedRfdAmount:((item.rfd-1)*item.rate).toFixed(2)})

dispatcchUserEvents("INCREASE_QTY",{itemIndex:item.systemId,qty:0,updatedAmount:((0)*item.rate).toFixed(2),rfd:item.rfd-1,updatedRfdAmount:((item.rfd-1)*item.rate).toFixed(2)})
// 19-jan-2024 add kiya tha ku k editable se phly agr special disc apply kiya tha to dobara kr den 
setSpecialDiscountPer(0)
setSpecialDiscountValue(0)

}}> <p style={{}}>-</p></button>
{/* <p style={{fontWeight:"bold",color:"#77787a"}}>    1</p> */}
<button style={{width:width<500?22:30,backgroundColor:"white",borderBottom:"2px solid white",borderTop:"2px solid white",borderLeft:"0px solid white",borderRight:"0px solid white"}}
><p style={{marginLeft:-4}}>{item.rfd}</p></button>
<button style={{width:width<500?22:30,backgroundColor:"#268ae1",border:"2px solid #268ae1",borderLeft:"2px solid #268ae1",borderTopRightRadius:5,borderBottomRightRadius:5,color:"white"}} onClick={()=>{
// let index = cartItems.findIndex(x => x.systemId == item.systemId);
// dispatcchUserEvents("PERCENTAGEUPDATE",{itemIndex:index,qty:item.qty+1,rfd:item.rfd,updatedAmount:((item.qty+1)*item.rate).toFixed(2),disc:item.discount,updatedRfdAmount:(item.rfd*item.rate).toFixed(2)})
// console.log("index")

// console.log(index)

// dispatcchUserEvents("INCREASE_QTY",{itemIndex:index,qty:item.qty+1,updatedAmount:((item.qty+1)*item.rate).toFixed(2),rfd:item.rfd,updatedRfdAmount:(item.rfd*item.rate).toFixed(2)})
let index = cartItems.findIndex(x => x.systemId == item.systemId);
// dispatcchUserEvents("PERCENTAGEUPDATE",{itemIndex:index,qty:item.qty+1,rfd:item.rfd,updatedAmount:((item.qty+1)*item.rate).toFixed(2),disc:item.discount,updatedRfdAmount:(item.rfd*item.rate).toFixed(2)})
console.log("index")

console.log(index)

// dispatcchUserEvents("INCREASE_QTY",{itemIndex:index,qty:item.qty+1,updatedAmount:((item.qty+1)*item.rate).toFixed(2),rfd:item.rfd,updatedRfdAmount:(item.rfd*item.rate).toFixed(2)})
// console.log("old data"+JSON.stringify(oldData))
console.log("new data"+JSON.stringify(item))

// 1-sep-2024
if(item.qty >item.stock){
alert(item.itemName+" Item Quantity is greater than Stock !")
}

if(item.qty==0&&item.rfd>0){
console.log("check oper rfd"+item.rfd+"-"+"qty"+item.qty)

  // item.rfd=0
}
if(item.rfd>0 &&item.qty!=0){
console.log("check niche"+item.rfd+"-"+"qty"+item.qty)

item.qty=0

}

console.log("index is "+index)
const dataUpdate = [...cartItems];
console.log("New data is "+JSON.stringify(item))
// resolve()
dispatcchUserEvents("PERCENTAGEUPDATE",{itemIndex:item.systemId,qty:0,rfd:(item.rfd+1),updatedAmount:((0)*item.rate).toFixed(2),disc:item.disc,updatedRfdAmount:((item.rfd+1)*item.rate).toFixed(2)})

dispatcchUserEvents("INCREASE_QTY",{itemIndex:item.systemId,qty:0,updatedAmount:((0)*item.rate).toFixed(2),rfd:item.rfd+1,updatedRfdAmount:((item.rfd+1)*item.rate).toFixed(2)})
// 19-jan-2024 add kiya tha ku k editable se phly agr special disc apply kiya tha to dobara kr den 
setSpecialDiscountPer(0)
setSpecialDiscountValue(0)
}}><p style={{}}>+</p></button>

</div>



</div>
           <div style={{width:"400%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} >

{/* <p style={{fontWeight:"bold",color:"#268ae1"}}>    Net Value</p> */}
<p style={{fontWeight:"bold",color:"#000000"}}>$ {item.disc} </p>


</div>   <div style={{width:"400%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} >

{/* <p style={{fontWeight:"bold",color:"#268ae1"}}>    Net Value</p> */}
<p style={{fontWeight:"bold",color:"#000000"}}>$ {item.discount} </p>


</div>   <div style={{width:"400%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} >

{/* <p style={{fontWeight:"bold",color:"#268ae1"}}>    Net Value</p> */}
<p style={{fontWeight:"bold",color:"#000000"}}>$ {item.netvalue} </p>


</div>    
<div style={{width:"400%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} >

{/* <p style={{fontWeight:"bold",color:"#268ae1"}}>    Net Value</p> */}
<p style={{fontWeight:"bold",color:"#cb1a2d"}}>{item.stock} </p>


</div>   

           <div style={{width:"300%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",}} >

{/* <p style={{fontWeight:"bold",color:"#268ae1"}}>    Net Value</p> */}

<RemoveRedEyeOutlinedIcon
style={{color:"#5794ca",

marginTop:-10}}
onClick={() => {
setopenImageView(true)
setimageloading(true)
  var obj={
// branch_id:1,
system_id:item.systemId,

user_id:localStorage.getItem('user_id')
}
console.log("check obj"+JSON.stringify(obj))
 fetch("https://4dserver.jtelwholesale.com/4DACTION/WebPictureCallsProductSingle",

 {
   method:"POST",
   'Content-Type':"application/json",
   body: JSON.stringify(obj)
 }).then((res) => res.json()).then((response) => {
  if(response[0].request_status=="No Picture"){
    setopenImageView(false)
    alert("No Picture Found !")
setimageloading(false)


    return
  }
   console.log("response "+JSON.stringify(response))
   setSelectedReport(`${tesTmg}`+response[0].picture_image)
setimageloading(false)

  
  
 })
 .catch((err)=>{
  console.log(err)
setimageloading(false)

 })
// setsystemId(newValue.system_id)


}}
></RemoveRedEyeOutlinedIcon>



</div>
<div style={{width:"300%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",}} >

{/* <p style={{fontWeight:"bold",color:"#268ae1"}}>    Net Value</p> */}

<DeleteIcon style={{fontSize:width<500?"24px":"24px",paddingTop:width<500?0:0,color:"#5794ca",marginTop:-10}} className='iconcss'  onClick={()=>{
  // alert("chak de phatty"+JSON.stringify(item))
  let index = cartItems.findIndex(x => x.systemId == item.systemId);

                        // console.log("check delete data"+JSON.stringify(oldData))
                        dispatcchUserEvents("REMOVE_FROM_CART",{data:item,deductedAmount:item.qty*item.netvalue,index})
                        setSpecialDiscountPer(0)
                        setSpecialDiscountValue(0)
}}></DeleteIcon>


</div>
               </div>
  )
})}
{cartItems.length==0 ?<ShoppingCartIcon style={{color:"#268ae1",height:"50%",width:"50%",alignSelf:"center"}} />:<></>} 

</div>
              </>
            }
            </GridItem>
            {/* <GridItem xs={3} sm={3} md={3} lg={1.5}>
              <p style={{ color: 'black' }}>Just Booked</p>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} lg={1}>
              <div style={{
                backgroundColor: 'red', width: "50%",
                height: "50%"
              }}>
              </div>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} lg={1.5}>
              <p style={{ color: 'black' }}>Only Phlebotomy Done</p>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} lg={1}>
              <div style={{
                backgroundColor: 'purple', width: "50%",
                height: "50%"
              }}>
              </div>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} lg={1.5}>
              <p style={{ color: 'black' }}>Lying Pending Results</p>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} >
              <div style={{
                backgroundColor: 'blue', width: "50%",
                height: "50%"
              }}>
              </div>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} lg={1.5}>
              <p style={{ color: 'black' }}>Results Done But not Signed</p>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} >
              <div style={{
                backgroundColor: 'green', width: "50%",
                height: "50%"
              }}>
              </div>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} lg={1.5}>
              <p style={{ color: 'black' }}>Results Ready</p>
            </GridItem> */}

          </GridContainer>
          <GridContainer >
                     
                     <GridItem sm={12} md={12}lg={4} >
                     <span style={{
       display: 'flex',
       flexDirection: "column",
       marginTop: 6,
       marginLeft:width<960?0:25
     }}>
       <b>Remarks</b>
       <TextareaAutosize
         rowsMax={4}
         style={{
           padding: '0.5em',
           height: '5em',
           marginLeft: 1
         }}
         value={remarks}
         onChange={(e) => {
           setremarks(e.target.value)
         }}
         placeholder="Transaction Remarks"
       /></span>
                     </GridItem>
                     <GridItem sm={12} md={12} lg={4} style={{marginTop:"2%"}}>
                     {/* <GridItem sm={6} md={6} lg={12}> */}
                     <TextField
            required
            id="outlined-required"
            label="Refund Total $"
            style={{
              borderRadius: 5,
              // width:"22%",
              marginTop:width<500?10:10,

              width:width<500?"48%":"36.8%",
              // marginRight:"4%"
              marginRight:width<500?"4%":"3.2%"
             
            }}
            // value={billAmount}
            value={TotalRfdAmount}

            type="number"
           
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          />
                     <TextField
            // required
            id="outlined-required"
            label="Refund Discount $"
            style={{
              borderRadius: 5,
              // width:"22%",
              width:width<500?"48%":"36.8%",
              marginTop:width<500?10:10,

              marginRight:width<500?"0%":"3.2%"
              
            }}
            // value={billAmount}
            value={TotalRefundDiscountAmount}

            type="number"
           
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          /> 
           <TextField
          // required
          id="outlined-required"
          label="Refund Spec Disc %"
          style={{
            borderRadius: 5,
            width:width<500?"48%":"36.8%",
            marginTop:width<500?10:10,

            // marginRight:"2%"
            marginRight:width<500?"4%":"3.2%"

            
          }}
          // value={billAmount}
          value={rfdSpecialDiscountPer}

          type="number"
         
          variant="outlined"
          InputProps={{
            readOnly:localStorage.getItem('If_allow_discount')=="true"?false:true,
          }}
          onChange={(e) => {
              
            if(e.target.value<100){
              console.log("rfdSpecialDiscountPer is"+e.target.value)
              let totalPer =((e.target.value/100)*TotalRfdAmount)
              console.log("totalPer is"+totalPer)
              
             
              let totalPerAmount=(TotalRfdPayable-totalPer).toFixed(2)
              console.log("Total AMOUNT  is"+totalPerAmount)
              if(totalPerAmount<0){
                alert("do not apply more than Total Amount ")
                setrfdSpecialDiscountPer(0)
                setrfdSpecialDiscountValue(0)
              }
            else{
      dispatcchUserEvents("RfdSpecialDiscount",{rfdSpecialDiscountPerAmount:totalPerAmount,onChangeValue:e.target.value})
      setrfdSpecialDiscountValue(totalPer.toFixed(2))
     setrfdSpecialDiscountPer(e.target.value)

            }
            
            // setrfdSpecialDiscountPer(e.target.value)
          }else{
            alert("Special Disc must be less than 100")
          }
       
    
          }}
          
        />
         {/* {rfdSpecialDiscountPer>0 &&  <Button
                variant="contained"
                color="primary"
                // disabled={selectedValue == 'Normal' || AllowDiscount ? false : true}
                style={{
                  // height: '50%',
                  margin: "1em",
                  backgroundColor:"#cb1a2d"

                }}
                onClick={
                  async () => {
                 
                      if(TotalRfdAmount){

          
          
          console.log("rfdSpecialDiscountPer is"+rfdSpecialDiscountPer)
          let totalPer =((rfdSpecialDiscountPer/100)*TotalRfdAmount)
          console.log("totalPer is"+totalPer)
          
         
          let totalPerAmount=(TotalRfdPayable-totalPer).toFixed(2)
          console.log("Total AMOUNT  is"+totalPerAmount)
          if(totalPerAmount<0){
            alert("do not apply more than Total Amount ")
          }
        else{
  dispatcchUserEvents("RfdSpecialDiscount",{rfdSpecialDiscountPerAmount:totalPerAmount})
  setrfdSpecialDiscountValue(totalPer.toFixed(2))
 setrfdSpecialDiscountPer(rfdSpecialDiscountPer)
        }
                        
                        }
                         else{
                          alert("no refund Products show ")
                         }
                 






                  }
                }>Apply</Button>} */}
              <TextField
          // required   
          id="outlined-required"
          label="Refund Spec Disc $"
          style={{
            borderRadius: 5,
            width:width<500?"48%":"36.8%",
            marginTop:width<500?10:10,

            // marginRight:"2%"
            marginRight:width<500?"0%":"3.2%"

            
          }}
          // value={billAmount}
          value={rfdSpecialDiscountValue}

          type="number"
         
          variant="outlined"
          InputProps={{
            readOnly:localStorage.getItem('If_allow_discount')=="true"?false:true,
          }}
          onChange={(e) => {
              
            // if(e.target.value<100){
              let totalPercentage =((e.target.value*100)/TotalRfdAmount)

              let totalPer =((totalPercentage/100)*TotalRfdAmount)
              console.log("totalPer is"+totalPer)
              
             
              let totalPerAmount=(TotalRfdPayable-totalPer).toFixed(2)
              console.log("Total AMOUNT  is"+totalPerAmount)
              if(totalPerAmount<0){
                alert("do not apply more than Total Amount ")
                setrfdSpecialDiscountPer(0)
                setrfdSpecialDiscountValue(0)
              }
            else{
      dispatcchUserEvents("RfdSpecialDiscount",{rfdSpecialDiscountPerAmount:totalPerAmount,onChangeValue:e.target.value})
      setrfdSpecialDiscountValue(e.target.value)
     setrfdSpecialDiscountPer(totalPercentage.toFixed(2))

            }
            
            
            // setrfdSpecialDiscountValue(e.target.value)
          // }else{
          //   alert("Special Disc must be less than 100")
          // }
       
    
          }}
          
        />
                    
         
                     <TextField
            // required
            id="outlined-required"
            label="Refund Net Val $"
            style={{
              borderRadius: 5,
              width:width<500?"48%":"36.8%",

              // marginRight:"2%",
              marginRight:width<500?"0%":"3.2%",

              marginTop:width<500?10:0,

              marginTop:10,
              // marginLeft:5
            }}
            // value={TotalRfdPayable}
            value={TotalRfdPayableAddDisc}

           
            type="number"
           
            variant="outlined"
             InputProps={{
            readOnly: true,
          }}
          />
             {/* next four */}
                     {/* <TextField
            // required
            id="outlined-required"
            label="Amount $"
            style={{
              borderRadius: 5,
              width:width<500?"48%":"36.8%",

              // marginRight:"2%",
            marginRight:width<500?"4%":"3.2%",

              marginTop:10,
              // marginLeft:5
            }}
            value={TotalAmount}
            type="number"
           
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          /> 
       
           <TextField
          // required
          id="outlined-required"
          label="Discount $"
          style={{
            borderRadius: 5,
            width:width<500?"48%":"36.8%",

            marginTop:10,

            // marginRight:"2%"
            marginRight:width<500?"0%":"3.1%",

            // marginTop:6,
            // marginLeft:5
          }}
          value={TotalDiscount}
          type="number"
         
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
        />
            <TextField
            // required
            id="outlined-required"
            label="Spec Discount per %"
            style={{
              borderRadius: 5,
              width:width<500?"48%":"36.8%",

              // marginRight:"2%",
            marginRight:width<500?"4%":"3.2%",

              marginTop:10,

              // marginTop:6,
              // marginLeft:5
            }}
            value={specialDiscountPer}
            type="number"
           
            variant="outlined"
            onChange={(e) => {
              
              if(e.target.value<100){

              
              // setSpecialDiscountPer(e.target.value)
              let totalVal =(TotalAmount-e.target.value)
              let totalPer =((e.target.value/100)*TotalAmount)
              // total kitna special discount hua ye save kiya hai send krna hai 
              setSpecialDiscountValue(totalPer.toFixed(2))
              console.log("special discount amount is"+totalPer)
              let totalPerAmount=(TotalPayable-totalPer).toFixed(2)
              console.log("Total AMOUNT  is"+totalPerAmount)
              if(totalPerAmount<0){
                alert("do not apply more than Total Amount ")
              }
            else{
              console.log("check TotalDiscount+totalPerAmount"+(TotalDiscount+totalPerAmount))
              if(TotalDiscount+totalPerAmount>TotalAmount){
                alert("do not apply more than Total")
              }
              else{
              // if(e.target.value!=0){
                dispatcchUserEvents("SpecialDiscount",{specialDiscountVal:totalVal,specialDiscountPer:totalPerAmount,onChangeValue:e.target.value})

              // }
              }
     setSpecialDiscountPer(e.target.value)
            }
            }else{
              alert("Special Disc must be less than 100")
            }
         
      //         if(netValue>0){
      //    var total= ((discount)/(100))*netValue
      //    setnetValue(netValue-total)
      //     }
            }}
          />  
            <TextField
            // required
            id="outlined-required"
            label="Spec Discount Value $"
            style={{
              borderRadius: 5,
              width:width<500?"48%":"36.8%",

              // marginRight:"2%",
            marginRight:width<500?"4%":"3.2%",

              marginTop:10,

              // marginTop:6,
              // marginLeft:5
            }}
            value={specialDiscountValue}
            type="number"
           
            variant="outlined"
            onChange={(e) => {
              // 
              let totalPercentage =((e.target.value*100)/TotalAmount)
              console.log("total percent is "+totalPer)
              // let totalVal =(TotalAmount-e.target.value)
              let totalPer =((totalPercentage/100)*TotalAmount)
              // total kitna special discount hua ye save kiya hai send krna hai 
             
              let totalPerAmount=(TotalPayable-totalPer).toFixed(2)
              console.log("Total AMOUNT  is"+totalPerAmount)
              if(totalPerAmount<0){
                alert("do not apply more than Total Amount ")
              }
            else{
              if(TotalDiscount+totalPerAmount>TotalAmount){
                alert("do not apply more than Total Amount")
              }
              else{
              // if(e.target.value!=0){
                dispatcchUserEvents("SpecialDiscount",{specialDiscountPer:totalPerAmount,onChangeValue:totalPercentage})

              // }
              }
            setSpecialDiscountPer(totalPercentage.toFixed(2))

    
            }
            setSpecialDiscountValue(e.target.value)



             
              console.log("check "+e.target.value)
              
         
            }}
          />  
         
          
          
          <TextField
          // required
          id="outlined-required"
          label="Payable $"
          style={{
            borderRadius: 5,
            width:width<500?"48%":"36.8%",

            // marginRight:"2%",
            marginRight:width<500?"0%":"3.2%",

            marginTop:10,

            // marginTop:6,
            // marginLeft:5
          }}
          // value={TotalPayable}
          value={TotalPayableAddDisc}
          
          
          type="number"
         
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
        /> */}
                   
           {/* </GridItem> */}
                     </GridItem>
                     <GridItem sm={12} md={12} lg={4} style={{marginTop:"2%"}}>
                     {/* <GridItem sm={6} md={6} lg={12}> */}
                     {/* <TextField
            required
            id="outlined-required"
            label="Refund Total"
            style={{
              borderRadius: 5,
              // width:"22%",
              marginTop:width<500?10:0,

              width:width<500?"48%":"22%",
              // marginRight:"4%"
              marginRight:width<500?"4%":"2%"
             
            }}
            // value={billAmount}
            value={TotalRfdAmount}

            type="number"
           
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          />
                     <TextField
            required
            id="outlined-required"
            label="Refund Discount"
            style={{
              borderRadius: 5,
              // width:"22%",
              width:width<500?"48%":"22%",
              marginTop:width<500?10:0,

              marginRight:width<500?"0%":"2%"
              
            }}
            // value={billAmount}
            value={TotalRefundDiscountAmount}

            type="number"
           
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          /> 
           <TextField
          required
          id="outlined-required"
          label="Refund Spec Disc"
          style={{
            borderRadius: 5,
            width:width<500?"48%":"22%",
            marginTop:width<500?10:0,

            // marginRight:"2%"
            marginRight:width<500?"4%":"2%"

            
          }}
          // value={billAmount}
          value={rfdSpecialDiscountPer}

          type="number"
         
          variant="outlined"
          onChange={(e) => {
              
            if(e.target.value<100){

            
            setrfdSpecialDiscountPer(e.target.value)
          }else{
            alert("Special Disc must be less than 100")
          }
       
    //         if(netValue>0){
    //    var total= ((discount)/(100))*netValue
    //    setnetValue(netValue-total)
    //     }
          }}
          
        />
         {rfdSpecialDiscountPer>0 &&  <Button
                variant="contained"
                color="primary"
                // disabled={selectedValue == 'Normal' || AllowDiscount ? false : true}
                style={{
                  // height: '50%',
                  margin: "1em",
                  backgroundColor:"#cb1a2d"

                }}
                onClick={
                  async () => {
                    // if(cartItems>0){
                      if(TotalRfdAmount){

          
          // let totalVal =(TotalAmount-specialDiscountPer)
          console.log("rfdSpecialDiscountPer is"+rfdSpecialDiscountPer)
          let totalPer =((rfdSpecialDiscountPer/100)*TotalRfdAmount)
          console.log("totalPer is"+totalPer)
          
          // total kitna special discount hua ye save kiya hai send krna hai 
          // setSpecialDiscountValue(totalPer)
          // console.log("special discount amount is"+totalPer)
          let totalPerAmount=(TotalRfdPayable-totalPer).toFixed(2)
          console.log("Total AMOUNT  is"+totalPerAmount)
          if(totalPerAmount<0){
            alert("do not apply more than Total Amount ")
          }
        else{
  dispatcchUserEvents("RfdSpecialDiscount",{rfdSpecialDiscountPerAmount:totalPerAmount})
  setrfdSpecialDiscountValue(totalPer.toFixed(2))
 setrfdSpecialDiscountPer(rfdSpecialDiscountPer)
        }
                        
                        }
                         else{
                          alert("no refund Products show ")
                         }
                    // }
                    // else{
                    //   alert("Please select Products ")
                    //   // setdiscount(0)
                    // }






                  }
                }>Apply</Button>}
          
                    
         
                     <TextField
            required
            id="outlined-required"
            label="Refund Net Value"
            style={{
              borderRadius: 5,
              width:width<500?"48%":"22%",

              // marginRight:"2%",
              marginRight:width<500?"0%":"2%",

              marginTop:width<500?10:0,

              // marginTop:6,
              // marginLeft:5
            }}
            value={TotalRfdPayable}
            type="number"
           
            variant="outlined"
             InputProps={{
            readOnly: true,
          }}
          /> */}
             {/* next four */}
                     <TextField
            // required
            id="outlined-required"
            label="Amount $"
            style={{
              borderRadius: 5,
              width:width<500?"48%":"36.8%",

              // marginRight:"2%",
            marginRight:width<500?"4%":"3.2%",

              marginTop:10,
              // marginLeft:5
            }}
            value={TotalAmount}
            type="number"
           
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          /> 
       
           <TextField
          // required
          id="outlined-required"
          label="Discount $"
          style={{
            borderRadius: 5,
            width:width<500?"48%":"36.8%",

            marginTop:10,

            // marginRight:"2%"
            marginRight:width<500?"0%":"3.1%",

            // marginTop:6,
            // marginLeft:5
          }}
          value={TotalDiscount}
          type="number"
         
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
        />
            <TextField
            // required
            id="outlined-required"
            label="Spec Discount per %"
            style={{
              borderRadius: 5,
              width:width<500?"48%":"36.8%",

              // marginRight:"2%",
            marginRight:width<500?"4%":"3.2%",

              marginTop:10,

              // marginTop:6,
              // marginLeft:5
            }}
            value={specialDiscountPer}
            type="number"
           
            variant="outlined"
            InputProps={{
              readOnly:localStorage.getItem('If_allow_discount')=="true"?false:true,
            }}
            onChange={(e) => {
              
              if(e.target.value<100){

              
              // setSpecialDiscountPer(e.target.value)
              let totalVal =(TotalAmount-e.target.value)
              let totalPer =((e.target.value/100)*TotalAmount)
              // total kitna special discount hua ye save kiya hai send krna hai 
              console.log("special discount amount is"+totalPer)
              let totalPerAmount=(TotalPayable-totalPer).toFixed(2)
              console.log("Total AMOUNT  is"+totalPerAmount)
              if(totalPerAmount<0){
                alert("do not apply more than Total Amount ")
              }
            else{
              console.log("check TotalDiscount+totalPerAmount"+(TotalDiscount+totalPerAmount))
              if(TotalDiscount+totalPerAmount>TotalAmount){
                // alert("do not apply more than Total")
              }
              else{
              // if(e.target.value!=0){
                dispatcchUserEvents("SpecialDiscount",{specialDiscountVal:totalVal,specialDiscountPer:totalPerAmount,onChangeValue:e.target.value})

              // }
              }
              setSpecialDiscountValue(totalPer.toFixed(2))

     setSpecialDiscountPer(e.target.value)
            }
            }else{
              alert("Special Disc must be less than 100")
            }
         
      //         if(netValue>0){
      //    var total= ((discount)/(100))*netValue
      //    setnetValue(netValue-total)
      //     }
            }}
          />  
            <TextField
            // required
            id="outlined-required"
            label="Spec Discount Val $"
            style={{
              borderRadius: 5,
              width:width<500?"48%":"36.8%",

              // marginRight:"2%",
            marginRight:width<500?"0%":"3.2%",

              marginTop:10,

              // marginTop:6,
              // marginLeft:5
            }}
            value={specialDiscountValue}
            type="number"
           
            variant="outlined"
            InputProps={{
              readOnly:localStorage.getItem('If_allow_discount')=="true"?false:true,
            }}
            onChange={(e) => {
              // 
              let totalPercentage =((e.target.value*100)/TotalAmount)
              console.log("total percent is "+totalPer)
              // let totalVal =(TotalAmount-e.target.value)
              let totalPer =((totalPercentage/100)*TotalAmount)
              // total kitna special discount hua ye save kiya hai send krna hai 
             
              let totalPerAmount=(TotalPayable-totalPer).toFixed(2)
              console.log("Total AMOUNT  is"+totalPerAmount)
              if(totalPerAmount<0){
                alert("do not apply more than Total Amount ")
              }
            else{
              if(TotalDiscount+totalPerAmount>TotalAmount){
                alert("do not apply more than Total Amount")
              }
              else{
              // if(e.target.value!=0){
                dispatcchUserEvents("SpecialDiscount",{specialDiscountPer:totalPerAmount,onChangeValue:totalPercentage})

              // }
              }
            setSpecialDiscountPer(totalPercentage.toFixed(2))
            setSpecialDiscountValue(e.target.value)

    
            }



             
              console.log("check "+e.target.value)
              
         
            }}
          />  
            {/* {specialDiscountPer>0 &&  <Button
                variant="contained"
                color="primary"
                // disabled={selectedValue == 'Normal' || AllowDiscount ? false : true}
                style={{
                  // height: '50%',
                  margin: "1em",
                  backgroundColor:"#cb1a2d"

                }}
                onClick={
                  async () => {
                    // if(cartItems>0){
                      if(TotalAmount){

          let totalVal =(TotalAmount-specialDiscountPer)
          let totalPer =((specialDiscountPer/100)*TotalAmount)
          // total kitna special discount hua ye save kiya hai send krna hai 
          setSpecialDiscountValue(totalPer.toFixed(2))
          console.log("special discount amount is"+totalPer)
          let totalPerAmount=(TotalPayable-totalPer).toFixed(2)
          console.log("Total AMOUNT  is"+totalPerAmount)
          if(totalPerAmount<0){
            alert("do not apply more than Total Amount ")
          }
        else{
  dispatcchUserEvents("SpecialDiscount",{specialDiscountVal:totalVal,specialDiscountPer:totalPerAmount})
 setSpecialDiscountPer(specialDiscountPer)
        }
                        
                        }
                         else{
                          alert("Please Select Products ")
                         }
                  }
                }>Apply</Button>} */}
          
          
          <TextField
          // required
          id="outlined-required"
          label="Payable $"
          style={{
            borderRadius: 5,
            width:width<500?"48%":"36.8%",

            // marginRight:"2%",
            marginRight:width<500?"0%":"3.2%",

            marginTop:10,

            // marginTop:6,
            // marginLeft:5
          }}
          // value={TotalPayable}
          value={TotalPayableAddDisc}
          
          
          type="number"
         
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
        />
                   
           {/* </GridItem> */}
                     </GridItem>
                     </GridContainer>
                     <GridContainer style={{marginTop:"2%"}}>
                     <GridItem sm={12} md={12} lg={6}></GridItem>
                     <GridItem sm={12} md={12} lg={6} style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                     <Button
           variant="contained"
           color="primary"
           onClick={() => {
       
            //  SeveRecordHandler()
            // alert("check data "+remarks+from.toISOString())
            // window.location.reload();
            history.push('/Home');  

            
           }}
   
           // disabled={saveInvoice}
           style={{
             textAlign: 'center',
             width: "14.5em",
             padding: "1em",
             height: "4em",
             backgroundColor:"#cb1a2d"

            //  marginLeft: "8px",
            //  marginTop:25,
            //  marginBottom:10
           }}>
             Update Cancel
           </Button>
           <Button
           variant="contained"
           color="primary"
           onClick={() => {
       
            //  SeveRecordHandler()
              //imp point refund_special_discount_percent:"", ye pdf me discunt hai
              if (clientId){
                if(cartItems.length!=0){
              var ArrEntrySequence=[]
              var ArrSequenceNo=[]
              var ArrItemSystemID=[]
              var ArrItemCode=[]
              var ArrItemName=[]
              var ArrItemQty=[]
              var ArrItemRate=[]
              var ArrItemAmount=[]
              var ArrItemUnitCost=[]
              var ArrDiscountPercent=[]
              var ArrDiscountValue=[]
              var ArrRefundQty=[]
              var ArrRefundValue=[]

              // for(let i=cartItems.length-1;i>=0;i--){
              for(let i=0;i<cartItems.length;i++){

                        console.log("check array result"+ JSON.stringify(cartItems[i]))
                        ArrEntrySequence.push(cartItems[i].sn)
                        ArrSequenceNo.push(cartItems[i].sn)
                        ArrItemSystemID.push(cartItems[i].systemId)
                        ArrItemCode.push(cartItems[i].code)
                        ArrItemName.push(cartItems[i].itemName)
                        ArrItemQty.push(cartItems[i].qty)
                        ArrItemRate.push(cartItems[i].rate)
                        ArrItemAmount.push(cartItems[i].amount)
                        // yhan pe net value ki jga buyingPrice aaega 
                        ArrItemUnitCost.push(parseFloat((cartItems[i].netvalue)))
                        ArrDiscountPercent.push(cartItems[i].disc)
                        ArrDiscountValue.push(parseFloat(cartItems[i].discount))
                        ArrRefundQty.push(cartItems[i].rfd)
                        ArrRefundValue.push(parseFloat((cartItems[i].rfdAmount)))


                        // ArrayInvoiceNo.push(totalinvoice[i].id)
                        // ArrayInvoiceOutstanding.push(totalinvoice[i].payable)
                        // ArrayInvoiceReceived.push(totalinvoice[i].receivedValue)
              }
              //client id        01CL00724
              // client title    1 10 MART
            let data={
              invoice_no:invoiceId,
              branch_id:branchId,
              invoice_date:invoiceDate,
              invoice_remarks:remarks,
              client_id:clientId,
              client_title:clientTitle,
              user_id:localStorage.getItem('user_id'),
              user_name:localStorage.getItem('user_name'),
              inv_amount:parseFloat(TotalAmount),
              inv_discount_value:TotalDiscount,
              inv_discount_special_percent:parseFloat(specialDiscountPer)?parseFloat(specialDiscountPer):0,
              inv_discount_special_value:parseFloat(specialDiscountValue)?parseFloat(specialDiscountValue):0,
           
              // totalPayable ab change hai while jo ab hai wo dalna hai
              // invoice_net_value:parseFloat(TotalPayable),
              invoice_net_value:parseFloat(TotalPayableAddDisc),

              refund_amount:parseFloat(TotalRfdAmount),
              refund_line_discount:TotalRefundDiscountAmount,
              refund_special_discount_percent:parseFloat(rfdSpecialDiscountPer),
              refund_special_discount_value:parseFloat(rfdSpecialDiscountValue),
              refund_net_value:parseFloat(TotalRfdPayable),
              ArrEntrySequence:ArrEntrySequence,
              ArrSequenceNo:ArrSequenceNo,
              ArrItemSystemID:ArrItemSystemID,
              ArrItemCode:ArrItemCode,
              ArrItemName:ArrItemName,
              ArrItemQty:ArrItemQty,
              ArrItemRate:ArrItemRate,
              ArrItemAmount:ArrItemAmount,
              ArrItemUnitCost:ArrItemUnitCost,
              ArrDiscountPercent:ArrDiscountPercent,
              ArrDiscountValue:ArrDiscountValue,
              ArrRefundQty:ArrRefundQty,
              ArrRefundValue:ArrRefundValue

            }
            console.log("check obj "+JSON.stringify(data))
            // fetch("https://4dserver.jtelwholesale.com/4DACTION/WebInvoiceSavingUpdating",
            fetch("https://4dserver.jtelwholesale.com/4DACTION/WebInvoiceSavingUpdating",

            {
              method:"POST",
              'Content-Type':"application/json",
              body: JSON.stringify(data)
            }).then((res) => res.json()).then((response) => {
            // console.log("#############   check response Saving Invoice ################################ "+JSON.stringify(response))  
// alert("Record Save "+response.transaction_status+"")
         
            localStorage. removeItem('client_id')
          localStorage. removeItem('client_Title')
          alert("Invoice Update Successfully !")
          history.push("/invoice")
          

          
        
            })
          }
          else{
            alert("Please Select Products")

          }
            }else{
              alert("Please Select Previous Invoice")
            }
           }}
   
           // disabled={saveInvoice}
           style={{
             textAlign: 'center',
             width: "14.5em",
             padding: "1em",
             height: "4em",
             marginLeft:"2%",
            //  marginLeft: "8px",
            //  marginTop:25,
            //  marginBottom:10
            backgroundColor:"#cb1a2d"

           }}>
               Update Invoice
           </Button>
           </GridItem>
           </GridContainer>

        </div>
        
       
       


      </Paper>
     
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'xl'}
        fullWidth={true}
        open={open}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
          setOpen(false)
          
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h4 className={classes.modalTitle}><b>Please Select a Client</b></h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <MaterialTable
            columns={[
              { field: "select", title: "Select" ,
            },
             { field: "clientTitle", title: "Client's Title",
             render: rowData => {
               return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.clientTitle}</p>
             } },
             { field: "phone", title: "Phone",
             render: rowData => {
               return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.phone}</p>
             }},
             { field: "city", title: "City",
             render: rowData => {
               return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.city}</p>
             } },
             { field: "state", title: "State",
             render: rowData => {
               return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.state}</p>
             } },
             { field: "zip", title: "Zip",
             render: rowData => {
               return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.zip}</p>
             } },
             { field: "address", title: "Address",
             render: rowData => {
               return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.address}</p>
             } },
              


            ]}
            options={{
              // paging: false,
              headerStyle: {
                backgroundColor: "#5794ca",
                color: "#FFF",
                // fontSize: "17px",
                // textAlign: "center",
                fontWeight: "bold",
                // minHeight:"50em"
              },
            
              // rowStyle: rowData => {
              //   // if(rowData.tableData.isTreeExpanded === false && rowData.tableData.path.length === 1) {
              //   //   return {};
              //   // }
              //   // const rowBackgroundColor = constPathColors[rowData.tableData.path.length];
              //   return {backgroundColor:rowData.rfd>0? "#ffffe0":"",
              // };
              // }
            }}
            data={ClientList}
            title={"Clients"}
          />
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setOpen(false)
              window.close();
         

            }}
            color="danger"
            simple
          >
            Close
                    </Button>
        </DialogActions>
      </Dialog>
      {/* for prev invoice selection */}
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'xl'}
        fullWidth={true}
        open={openPreInvoice}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
          setopenPreInvoice(false)
          
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

<h4 className={classes.modalTitle}><b>Select Previous Invoice</b></h4>

        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >

<GridContainer style={{ padding: '2em' }}>
{width>500?<></>:
<GridItem xs={12} sm={6} md={3} lg={3} style={{marginLeft:width<500?0:0,marginBottom:width<500?10:0,marginTop:width<500?localStorage.getItem('client_Title')?0:5:0}}>
            <Button
           variant="contained"
           color="primary"
           onClick={() => {
       history.push("InvoiceNewQrCode")
        //  alert("hy")
        // setDialogBoxOpen(true)
    
        // if(showQrScanner){
        //   setshowQrScanner(false)
        // }else{
        // setshowQrScanner(true)
        // }
                // setDialogBoxOpen(false)
                
           }}
   
           // disabled={saveInvoice}
           style={{
            //  textAlign: 'center',
            //  width: "13.5em",
            // //  padding: "1em",
            // //  height: "4em",
            //  marginLeft:"2%",
            // //  marginLeft: "8px",
            // //  marginTop:25,
            // //  marginBottom:10
            // backgroundColor:"#268ae1",
            // marginTop:width<970?10:10
            textAlign: 'center',
            width: "13.5em",
           //  padding: "1em",
           //  height: "4em",
            marginLeft:"2%",
           //  marginLeft: "8px",
           //  marginTop:25,
           //  marginBottom:10
           backgroundColor:"#cb1a2d",
           marginTop:width<750?5:0

           }}>
<CropFreeIcon style={{width:20,height:20,color:"white",}}></CropFreeIcon>
               {/* Show Scanner  */}
               {showQrScanner?"Close Scanner":"Old Invoice Scan"} 
           </Button>
           
              {/* <h4>Qr / Barcode  scanner </h4> */}
              {/* <div id="reader"></div> */}
              {/* {scanResult?
              <>
              <div >success:{scanResult}</div>
              <div style={{}} id="reader" ></div>
</>
              :
              <div style={{}} id="reader" ></div>
              } */}
              
              </GridItem>
}
<GridItem xs={12} sm={6} md={3} lg={3} style={{}}>

<FormGroup style={{
              marginLeft:width<500?"": "5em"
            }}>
              <b style={{fontWeight:"bold"}}>Period Apply</b>
              <FormControlLabel
              labelStyle={{color: 'red'}}
                control={<Checkbox color="success" style ={{
                  color: "#5794ca",
                }}checked={ifPeriodApplyYes} onChange={(e) => {
                  setifPeriodApplyYes(e.target.checked)
                  if (e.target.checked) {
                   setifPeriodApplyNo(false)
                   setnoRecordFound(false)
                  }
                }} />}
                label="Yes"
              />
              <FormControlLabel
                control={<Checkbox color="success"  style ={{
                  color: "#5794ca",
                }} checked={ifPeriodApplyNo} onChange={(e) => {
                  setifPeriodApplyNo(e.target.checked)
                  if (e.target.checked) {
                   setifPeriodApplyYes(false)
                   setnoRecordFound(false)

                  }
                }} 
                />}
                label="No"
              />
            
            
              
            </FormGroup>
</GridItem>
          <GridItem xs={12} sm={6} md={3} lg={3} style={{marginTop:width<500?"4%":width<600?"2%":0}}>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                variant="inline"
                inputVariant="outlined"
                label="From"
                format="MM/dd/yyyy"
                value={fromdate}
    maxDate={from}

                InputAdornmentProps={{ position: "start" }}
                onChange={date => {
                  setFromDate(date)
                  setnoRecordFound(false)
                }
                }
              />
            </MuiPickersUtilsProvider>
          </GridItem>
          <GridItem xs={12} sm={6} md={3} lg={3} style={{marginTop:width<500?"4%":width<600?"2%":0}}>

<MuiPickersUtilsProvider utils={DateFnsUtils}>
  <KeyboardDatePicker
    variant="inline"
    inputVariant="outlined"
    label="to"
    format="MM/dd/yyyy"
    value={todate}
    maxDate={from}
    InputAdornmentProps={{ position: "start" }}
    onChange={date => {setToDate(date)
      setnoRecordFound(false)
    
    }
  }
  />
</MuiPickersUtilsProvider>
</GridItem>
<GridItem xs={12} sm={6} md={3} lg={3} style={{marginTop:width<500?"4%":width<600?"2%":0}}>


<Button
           variant="contained"
           color="primary"
           onClick={() => {
            // start #############
      // const test=[{invoice_no:"",branch_id:1,invoice_date:"2024-01-18T10:28:58.265Z",invoice_remarks:"",user_id:"04",user_name:"DJ",client_id:"01CL00724",client_title:"1 10 MART ",inv_amount:50.97,inv_discount_value:0,inv_discount_special_percent:0,inv_discount_special_value:0,invoice_net_value:50.97,refund_amount:0,refund_line_discount:0,refund_special_discount_percent:0,refund_special_discount_value:0,refund_net_value:0,ArrEntrySequence:[1,2,3],ArrSequenceNo:[1,2,3],ArrItemSystemID:[605,3825,2892],ArrItemCode:["","",""],ArrItemName:[" T-Shirts Plain (1/6ct)","Baked Candles Terpene Infused 13oz (1ct)","Blunt Effects AF Spray (1/18ct)"],ArrItemQty:[1,1,1],ArrItemRate:[14.99,2.49,33.49],ArrItemAmount:[14.99,2.49,33.49],ArrItemUnitCost:[14.99,2.49,33.49],ArrDiscountPercent:[0,0,0],ArrDiscountValue:[0,0,0],ArrRefundQty:[0,0,0],ArrRefundValue:[0,0,0]}]
      const test=[{"invoice_no":"","branch_id":1,"invoice_date":"2024-01-18T10:59:45.321Z","invoice_remarks":"","user_id":"04","user_name":"DJ","client_id":"01CL00724","client_title":"1 10 MART ","inv_amount":21.47,"inv_discount_value":0,"inv_discount_special_percent":10,"inv_discount_special_value":2.15,"invoice_net_value":19.32,"refund_amount":0,"refund_line_discount":0,"refund_special_discount_percent":0,"refund_special_discount_value":0,"refund_net_value":0,"ArrEntrySequence":[1,2,3],"ArrSequenceNo":[1,2,3],"ArrItemSystemID":[605,145,2611],"ArrItemCode":["","",""],"ArrItemName":[" T-Shirts Plain (1/6ct)"," TP GP 459510 ( 1CT )-ODR","10mm China male Slider 3.5\" ( 1CT )-ODR"],"ArrItemQty":[1,1,1],"ArrItemRate":[14.99,4.99,1.49],"ArrItemAmount":[14.99,4.99,1.49],"ArrItemUnitCost":[14.99,4.99,1.49],"ArrDiscountPercent":[0,0,0],"ArrDiscountValue":[0,0,0],"ArrRefundQty":[0,0,0],"ArrRefundValue":[0,0,0]}]
      
      // end ####################
      setnoRecordFound(false)

            var obj={
              branch_id:parseInt(localStorage.getItem('branch_id')),

              // branch_id:2,
              user_id:localStorage.getItem('user_id'),
              // user_id:"11",
        
              date_from:fromdate.toISOString(),
              date_till:todate.toISOString(),
              if_period_apply:ifPeriodApplyYes?true:false
            }
            console.log("check obj "+JSON.stringify(obj))
            // fetch("https://4dserver.jtelwholesale.com/4DACTION/WebInvoiceModifyLoadingList",
            fetch("https://4dserver.jtelwholesale.com/4DACTION/WebInvoiceModifyLoadingList",

            {
              method:"POST",
              'Content-Type':"application/json",
              body: JSON.stringify(obj)
            }).then((res) => res.json()).then((response) => {
            console.log("check Products response "+JSON.stringify(response)) 
            if (response[0].invoice_id!=""){
            var DataInvoice=[]
            response.map((item) => {
              // console.log(item)
             
              var obj = {
                invoice_id: item.invoice_id,
                // invoice_date:(new Date(item.invoice_date).getDate()) + "/" + (new Date(item.invoice_date).getMonth() + 1) + "/" + (new Date(item.invoice_date).getFullYear()) ,
                invoice_date:(new Date(item.invoice_date).getMonth() + 1) + "/" + (new Date(item.invoice_date).getDate())+ "/" + (new Date(item.invoice_date).getFullYear()) ,
              
                client_title: item.client_title,
                invoice_net_value: parseFloat(item.invoice_net_value).toFixed(2)+"$",
                invoice_remarks: item.invoice_remarks,
               
                select: (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                    backgroundColor:"#cb1a2d"
        
                    }}
                    className={classes.button}
                    onClick={() => {
                      setLoading(true)
                      // fetch("https://4dserver.jtelwholesale.com/4DACTION/WebInvoiceModifyLoadingContent",
                      fetch("https://4dserver.jtelwholesale.com/4DACTION/WebInvoiceModifyLoadingContent",

                      {
                        method:"POST",
                        'Content-Type':"application/json",
                        body: JSON.stringify({
                         invoice_id:item.invoice_id,
              user_id:localStorage.getItem('user_id'),
                        //  user_id:"11"
                        })
                      }).then((res) => res.json()).then((response) => {
                      
                      console.log("check InvoiceModifyLoadingContent response "+JSON.stringify(response))

                      console.log("check InvoiceModifyLoadingContent response "+JSON.stringify(response[0].ArrEntrySequence))
var temp =JSON.stringify(response)

          //  start #############
          var ArrayData=[]
          response.map((value)=>{
       
              console.log("check map  "+JSON.stringify(value.ArrEntrySequence))
              // JSON.parse(value.ArrEntrySequence).map((data)=>{
              // console.log("check map inner  "+JSON.stringify(data))

              // })
              
              JSON.parse(value.ArrEntrySequence).map((chk,index)=>{
              console.log("check map INNER  "+chk+"INDEX"+index)
              // console.log("check map QTY  "+value.ArrItemQty[index]+"INDEX"+index)
              
              const obj ={
                sn:JSON.parse(value.ArrSequenceNo)[index],
                systemId: JSON.parse(value.ArrItemSystemID)[index],
                code: JSON.parse(value.ArrItemCode)[index],
                itemName:JSON.parse( value.ArrItemName)[index],
                rate:JSON.parse(value.ArrItemRate)[index],
                qty:JSON.parse(value.ArrItemQty)[index],
                rfd:JSON.parse(value.ArrRefundQty)[index],
                rfdAmount:JSON.parse(value.ArrRefundValue)[index], 
                amount:JSON.parse(value.ArrItemAmount)[index],
                disc:JSON.parse(value.ArrDiscountPercent)[index],
                discount:JSON.parse(value.ArrDiscountValue)[index],
                netvalue:JSON.parse(value.ArrItemAmount)[index],

                buyingPrice:JSON.parse(value.ArrItemUnitCost)[index],
              }

ArrayData.push(obj)

              })
// for special discount 
setSpecialDiscountPer(value.inv_discount_special_percent)
setSpecialDiscountValue(value.inv_discount_value)
setrfdSpecialDiscountPer(value.refund_special_discunt_percent)
setrfdSpecialDiscountValue(value.refund_special_discount_value)
// for other variable
setInvoiceId(value.invoice_id)
setbranchId(value.branch_id)
setinvoiceDate(value.invoice_date)
setclientId(value.client_id)
setclientTitle(value.client_title)


            })
            console.log("check Cart Array"+JSON.stringify(ArrayData))
            setcount(ArrayData[0].sn+1)

            dispatcchUserEvents("UpdateInvoice",{updateArray:ArrayData})
            
            setopenPreInvoice(false)

            
          //   test.map((data)=>{
          //               console.log("check map  "+JSON.stringify(data))

          // })
          // end ################
          
                      // response[0].ArrEntrySequence.map((item,index)=>{
                      //   console.log("check map  "+JSON.stringify(item))

                      // })
                      setLoading(false)
                     })
                     
                    }}
                  >
                    Select
                  </Button>
                ),
              };
        
              DataInvoice.push(obj);
            });
            
            setPreInvoiceData(DataInvoice)
          }else{
            setnoRecordFound(true)
          }
        
            })
       
           
            
           }}
   
           // disabled={saveInvoice}
           style={{
             textAlign: 'center',
             width: "14.5em",
             padding: "1em",
             height: "4em",
             backgroundColor:"#cb1a2d"

            //  marginLeft: "8px",
            //  marginTop:25,
            //  marginBottom:10
           }}>
<SearchIcon  style={{width:20,height:20,color:"white",marginRight:5}}></SearchIcon >

             Search
           </Button>
          {noRecordFound &&  <h4><b style={{color:"red"}}>No Invoice Found</b></h4>}
</GridItem>
{/* mobile pe niche na nazar aae es k lye oper lga dya mobile */}
{width<500?<></>:
<GridItem xs={12} sm={6} md={3} lg={3} style={{marginLeft:width<500?0:0,marginBottom:width<500?10:0,marginTop:width<500?localStorage.getItem('client_Title')?0:5:0}}>
            <Button
           variant="contained"
           color="primary"
           onClick={() => {
       history.push("InvoiceNewQrCode")
        //  alert("hy")
        // setDialogBoxOpen(true)
    
        // if(showQrScanner){
        //   setshowQrScanner(false)
        // }else{
        // setshowQrScanner(true)
        // }
                // setDialogBoxOpen(false)
                
           }}
   
           // disabled={saveInvoice}
           style={{
            //  textAlign: 'center',
            //  width: "13.5em",
            // //  padding: "1em",
            // //  height: "4em",
            //  marginLeft:"2%",
            // //  marginLeft: "8px",
            // //  marginTop:25,
            // //  marginBottom:10
            // backgroundColor:"#268ae1",
            // marginTop:width<970?10:10
            textAlign: 'center',
            width: "13.5em",
           //  padding: "1em",
           //  height: "4em",
            marginLeft:"2%",
           //  marginLeft: "8px",
           //  marginTop:25,
           //  marginBottom:10
           backgroundColor:"#cb1a2d",
           marginTop:width<750?5:0

           }}>
<CropFreeIcon style={{width:20,height:20,color:"white",}}></CropFreeIcon>
               {/* Show Scanner  */}
               {showQrScanner?"Close Scanner":"Old Invoice Scan"} 
           </Button>
           
              {/* <h4>Qr / Barcode  scanner </h4> */}
              {/* <div id="reader"></div> */}
              {/* {scanResult?
              <>
              <div >success:{scanResult}</div>
              <div style={{}} id="reader" ></div>
</>
              :
              <div style={{}} id="reader" ></div>
              } */}
              
              </GridItem>
}

{/* <GridItem xs={6} sm={6} md={3} lg={3}>

<MuiPickersUtilsProvider utils={DateFnsUtils}>
  <KeyboardDatePicker
    variant="inline"
    inputVariant="outlined"
    label="From"
    format="dd/MM/yyyy"
    value={todate}
    InputAdornmentProps={{ position: "start" }}
    onChange={date => setToDate(date)}
  />
</MuiPickersUtilsProvider>
</GridItem> */}


{/* <GridItem xs={6} sm={6} md={3} lg={3}>

<FormControl variant="filled" className={classes.formControl}  style={{width:"-webkit-fill-available"}} >
        <InputLabel id="demo-simple-select-filled-label">Role</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={role}
          onChange={(e)=>{
       setRole(e.target.value)
          }}
        >
        <MenuItem  value={1}>JTEL</MenuItem>
          <MenuItem value={2}>Admin</MenuItem>
         
        </Select>
      </FormControl>
</GridItem> */}

         </GridContainer>
         {loading?  <center>
            <ReactLoading type={'spinningBubbles'} color={'#196c90'} height={'10em'} width={'10em'} />
          </center>:
          <MaterialTable
            columns={[
              { field: "select", title: "Select" },
              { field: "invoice_id", title: "InvoiceId",
              render: rowData => {
                return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.invoice_id}</p>
              }
             },
              { field: "invoice_date", title: "Date",
              render: rowData => {
                return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.invoice_date}</p>
              }},
              { field: "client_title", title: "Client Title",
              render: rowData => {
                return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.client_title}</p>
              } },
              { field: "invoice_net_value", title: "Net Value",
              render: rowData => {
                return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.invoice_net_value}</p>
              } },
              { field: "invoice_remarks", title: "Remarks",
              render: rowData => {
                return <p style={{ color: "black", fontWeight: "bold" }}>{rowData.invoice_remarks}</p>
              } },
             



            ]}
            options={{
              // paging: false,
              headerStyle: {
                backgroundColor: "#5794ca",
                color: "#FFF",
                // fontSize: "17px",
                // textAlign: "center",
                fontWeight: "bold",
                // minHeight:"50em"
              },
            
              // rowStyle: rowData => {
              //   // if(rowData.tableData.isTreeExpanded === false && rowData.tableData.path.length === 1) {
              //   //   return {};
              //   // }
              //   // const rowBackgroundColor = constPathColors[rowData.tableData.path.length];
              //   return {backgroundColor:rowData.rfd>0? "#ffffe0":"",
              // };
              // }
            }}
            data={PreInvoiceData}
            title={"Invoice Data"}
          />
        }
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              if(!invoiceId){
                history.push("/Invoice")
                              }
              setopenPreInvoice(false)
              window.close();

            }}
            color="danger"
            simple
          >
            Close
                    </Button>
        </DialogActions>
      </Dialog>

    </>
  );
}
