// import React ,{ useEffect } from "react";
import React,{useEffect,useState} from 'react'

import ReactDOM from "react-dom";
import { ProductContex } from './ContextStore';

import { createBrowserHistory } from "history";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.9.0";
//for jtell
import InvoiceNew from 'views/JtelInvoiceNew/Lab_Reports';
import InvoiceUpdate from 'views/JtelInvoiceUpdate/Lab_Reports';
import InvoicePdf from 'views/JtelInvoicePDF/Lab_Reports';
import AddNewClient from "views/JtelAddNewClient/Lab_Reports"
import InvoiceNewQrCode from "views/JtelInvoiceNewQrCodeScanner/Lab_Reports"



// pages for this product
import QuotationWithParam from 'views/Quotation/Quotation.js'
// 04-oct-2023 oper wala quotation new kr dya niche wala old hai without parameter
import Quotation from 'views/QuotationOld/Quotation'

import Components from "views/Components/Components.js";
import FindATest from "views/LandingPage/FindATest.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import RegisterPage from "views/RegistrationPage/Register.js";
import RecallPassword from "views/RecallPassword/RecallPassword";
import Booking_History from 'views/History/Booking_History'
// Departments
// import Clinic_Chem from 'views/Departments/clinic_chem'
// import Ultrasound from 'views/Departments/Ultrasound'
// import Hematology from 'views/Departments/Hematology'
import Covid from 'views/Departments/Covid'
// import Microbiology from 'views/Departments/Microbiology'
// import Molecular from 'views/Departments/Molecular'
// import Histopathology from 'views/Departments/Histopathology'
// import Echocardiography from 'views/Departments/Echocardiography'
// import Special_Pathology from 'views/Departments/Special_Pathology'
// import Fibroscan from 'views/Departments/Fibroscan'
// import DigitalXray from 'views/Departments/DigitalXray'
// import OPG from 'views/Departments/Opg'
// import Spirometry from 'views/Departments/Spirometry'




import OurMission from 'views/AboutUs/OurMission'

import HomeSampling from "views/HomeSampling/HomeSampling";
import ResetPassword from 'views/ResetPassword/ResetPassword'
import WhoWeAre from 'views/AboutUs/WhoWeAre.js'
import CovidReports from 'views/KSA_Reports/CovidReports'
import Emirates from 'views/Emirates/EmiratesLogin'
import Summary from 'views/Emirates_KSA_Summary/Summary'
import Fast_Track_Registration from "views/Fast_Track_Reg/Fast_Track_Registration";
import COVID19_HomeSampling from 'views/Covid19_HomeSampling/HomeSampling'
import View_Sample_Tracking from 'views/View_Sample_Tracking/View_Sample_Tracking';
import Update_Sample_Tracking from 'views/Update_Sampling_Record/Update_Sampling_Record'
import Sample_Tracking from 'views/sample_tracking/sample_tracking'
import UpdateArhamSerivces from 'views/UpdateArhamServices/UpdateArhamServices'
import SamplingHistory from 'views/History/Booking_History'
import FlyDubai from 'views/FlyDubai/flydubai'
import FlyDubaiReports from 'views/FlyDubaiReports/Summary'
import Overall_Covid_Reports from 'views/Overall_Covid_Reports/CovidReports'
import AirBlue from 'views/AirBlue/AirBlue'
import AirBlueSummary from 'views/AirBlue_Summary/Summary'
import FlyDubaiExcelExport from 'views/FlyDubaiExcelExport/Summary'
import QatarLogin from 'views/QatarLogin/Login'
import SereneAir from 'views/SereneAir/SereneAir'
import SereneAir_Summary from 'views/SereneAir_Summary/Summary'

import OmanAir from 'views/OmanAir/OmanAir'
import OmanAir_Summary from 'views/OmanAir_Summary/Summary'
import QatarSummary from 'views/Qatar_Summary/Summary'
import QatarExcelExport from 'views/QatarExcelExport/Summary'
import Bookings from 'views/BookingCounter/Bookings'

import Onix_Enterprises from 'views/Onix_Enterprise/Onix_Enterprise'

import Onix_Summary from 'views/Onix_Summary/Summary'
import GerrySummary from 'views/GerrysExcelExport/Summary'
import ExportKSACases from 'views/ExportKSACases/Summary'
import EtihadLogin from 'views/EtihadAirwaysLogin/Login'
import Etihad_Summary from 'views/Etihad_Airways/Summary'

import Gerrys_UTF_Service from 'views/Gerrys_UTF_Service/Summary'

import GerryOnlyExcelExport from 'views/GerrysOnlyExcelExport/Summary'

// Travelers Details
import Travelers from 'views/ExportTravelers/Summary'

// Airblue Summary Export
import AirBlueExport from 'views/AirBlue_Export/Summary'
//NewsAndFeeds 31-dec-2022
import NewsAndFeed from "views/NewsAndFeeds/NewsAndFeed";
// 07-march-2023
import Printing from "views/Printing/Printing";
// 25-march-2023
import Invoice from "views/InvoicingShow/Lab_Tests"
//21-JULY-2023
import CompanyProfile from "views/AboutUs/CompanyProfile";
//03-aug-2023
// import CeoMessage from 'views/AboutUs/CeoMessage'

// import OurTeam from "views/AboutUs/OurTeam";
// import OurGallery from "views/AboutUs/OurGallery";
//for future scientific 
// import Products from "views/FsPages/Products"
// import Principles from "views/FsPages/Principle"



var hist = createBrowserHistory();


 
//  const [bill,setbill]=useState("100")
 const MyContextProvider = ({ children }) => {
  //real
  const [billAmount,setbillAmount] = useState(0);
  // TotalAmount that are orignal value of products
  const [TotalAmount,setTotalAmount] = useState(0);
  const [TotalDiscount,setTotalDiscount] = useState(0);
  // pyabale amount es me se special discount minus hoga
  const [TotalPayable,setTotalPayable] = useState(0);
  const [TotalPayableAddDisc,setTotalPayableAddDisc] = useState(0);

  //for refund TotalAmount and totalPayable
  const [TotalRfdAmount,setTotalRfdAmount] = useState(0);
  const [TotalRfdPayable,setTotalRfdPayable] = useState(0);
  const [TotalRfdPayableAddDisc,setTotalRfdPayableAddDisc] = useState(0);

  const [TotalRefundDiscountAmount,setTotalRefundDiscountAmount]=useState(0)
  
  


  const [cartItems,setcartItems] = useState([]);

  // this is dummay
  
  const [billAmount2, setbillAmount2] = useState(0);

  const TotalCalculation =async()=>{
    // alert('Calling the data')
    let totalAmount=0
    let totalPayable=0
    // for refundAmount
    let totalRfdAmount=0
    let totalRfdPayable=0
    let calculateRefundDiscount=0
    let totalCalculateRefundDiscount=0



    let totalDiscount=0
     cartItems.map((i,index)=>{
// console.log("chak map "+JSON.stringify(i))
// console.log("chak map "+JSON.stringify(i.netvalue))
// totalAmount = (totalAmount+ parseFloat(i.amount))

totalAmount = (totalAmount+ parseFloat(i.rate)*i.qty)
totalPayable = (totalPayable+ parseFloat(i.netvalue))
// console.log("total amount"+totalAmount)
// totalDiscount
totalDiscount = (totalDiscount+ parseFloat(i.discount))
// totalrefund
totalRfdAmount=(totalRfdAmount+parseFloat(i.rfdAmount))
calculateRefundDiscount=(i.disc/100)*i.rfdAmount
totalCalculateRefundDiscount=totalCalculateRefundDiscount+calculateRefundDiscount
totalRfdPayable=(totalRfdPayable+parseFloat(i.rfdAmount-calculateRefundDiscount))



     })
     setTotalAmount(totalAmount.toFixed(2))
     setTotalPayable(totalPayable.toFixed(2))
     setTotalPayableAddDisc(totalPayable.toFixed(2))

     console.log("check Total AMOUNT"+totalAmount)
    //  totalDiscount
    setTotalDiscount(totalDiscount)
    //for total refund amount
    setTotalRfdAmount(totalRfdAmount.toFixed(2))
    // for totalRefundDiscount Value 
    setTotalRefundDiscountAmount(totalCalculateRefundDiscount)
    // refund payable me se jo refund discount dala hai us ko minus krna hai wo refund discount value cartitem me ni hai us k lye alag se var bna k case:"PERCENTAGEUPDATE" me us ko payable se minus krny lga hun
    setTotalRfdPayable(totalRfdPayable.toFixed(2))
    setTotalRfdPayableAddDisc(totalRfdPayable.toFixed(2))
      
   }
  //  for update value
  const TotalCalculationUpdate =async(data)=>{
    // alert('Calling the data')
    let totalAmount=0
    let totalPayable=0
    // for refundAmount
    let totalRfdAmount=0
    let totalRfdPayable=0
    let calculateRefundDiscount=0
    let totalCalculateRefundDiscount=0



    let totalDiscount=0
     data.map((i,index)=>{
// console.log("chak map "+JSON.stringify(i))
// console.log("chak map "+JSON.stringify(i.netvalue))
totalAmount = (totalAmount+ parseFloat(i.rate)*i.qty)
totalPayable = (totalPayable+ parseFloat(i.netvalue))
// console.log("total amount"+totalAmount)
// totalDiscount
totalDiscount = (totalDiscount+ parseFloat(i.discount))
// totalrefund
totalRfdAmount=(totalRfdAmount+parseFloat(i.rfdAmount))
calculateRefundDiscount=(i.disc/100)*i.rfdAmount
totalCalculateRefundDiscount=totalCalculateRefundDiscount+calculateRefundDiscount
totalRfdPayable=(totalRfdPayable+parseFloat(i.rfdAmount-calculateRefundDiscount))



     })
    //  setbillAmount(totalAmount.toFixed(2))
     setTotalAmount(totalAmount.toFixed(2))
     setTotalPayable(totalPayable.toFixed(2))
     setTotalPayableAddDisc(totalPayable.toFixed(2))

     console.log("check Total AMOUNT"+totalAmount)
    //  totalDiscount
    setTotalDiscount(totalDiscount)
    //for total refund amount
    setTotalRfdAmount(totalRfdAmount.toFixed(2))
    // for totalRefundDiscount Value 
    setTotalRefundDiscountAmount(totalCalculateRefundDiscount)
    // refund payable me se jo refund discount dala hai us ko minus krna hai wo refund discount value cartitem me ni hai us k lye alag se var bna k case:"PERCENTAGEUPDATE" me us ko payable se minus krny lga hun
    setTotalRfdPayable(totalRfdPayable.toFixed(2))
    setTotalRfdPayableAddDisc(totalRfdPayable.toFixed(2))
      
   }
  //  jo discount amount hai whan TotalValue change na ho es lye aisa
   const TotalCalculationForDisc =async()=>{
    // alert('Calling the data')
    let totalAmount=0
    let totalDiscount=0
     cartItems.map((i,index)=>{
console.log("chak map "+JSON.stringify(i))
console.log("chak map "+JSON.stringify(i.netvalue))

totalAmount = (totalAmount+ parseFloat(i.netvalue))
console.log("total amount"+totalAmount)
// totalDiscount
totalDiscount = (totalDiscount+ parseFloat(i.discount))


     })
    //  setTotalAmount(totalAmount)
     setTotalPayable(totalAmount)

     console.log("check Total AMOUNT"+totalAmount)
    //  totalDiscount
    setTotalDiscount(totalDiscount)
      
   }
  
  const dispatcchUserEvents =(actionType,payload)=>{
    switch (actionType) {
      case "LoginCred":
        console.log("Payload value Increase Qty "+JSON.stringify(payload))
        
        break;
      case "ADD_To_CART":
        let bill=0;
         console.log('Add to Cart in App.js Payload is : ',payload);
        // console.log('Payload Data : ',payload.data.price_Offered,'Payload requted QTY : ',payload.requestedQty);
        console.log("payload system id"+payload.data) 
        // changes 15-dec
    //     payload.data.requestedQuantity=parseInt(payload.requestedQty);
    // setcartItems([...cartItems,payload.data]);
    // bill = billAmount+(payload.data.price_Offered*payload.requestedQty);
    // setbillAmount(bill)
        if(cartItems.length==0){
          payload.data.requestedQuantity=parseInt(payload.requestedQty);
          setcartItems([payload.data,...cartItems]);
          bill = (billAmount+(payload.data.amount*payload.requestedQty));

          setbillAmount(bill)
          setTotalAmount(bill.toFixed(2))
          setTotalPayable(bill)
     setTotalPayableAddDisc(bill.toFixed(2))


      
         } else{
  
       var yes=   cartItems?.filter((product)=>product?.systemId==payload.data.systemId).map((item,index)=>{
            console.log(item)
            // payload.data.requestedQuantity=parseInt(payload.requestedQty);
            // setcartItems([...cartItems,payload.data]);
            // bill = billAmount+(payload.data.price_Offered*payload.requestedQty);
            // setbillAmount(bill)
           
          })
          if(yes.length==1 && payload.ifForceApply==false){
            alert("This item already add into cart")
          }
          else{
            payload.data.requestedQuantity=parseInt(payload.requestedQty);
            setcartItems([payload.data,...cartItems]);
          console.log("Total amount"+TotalAmount)
          // add this
          var val= (payload.data.amount*payload.requestedQty)
          console.log("val is"+val)
          bill = parseFloat((parseFloat(TotalAmount)+parseFloat(val)));
          var payablecalculate=parseFloat((parseFloat(TotalPayable)+parseFloat(val)))
          var discountCalculate=(payload.data.disc)
        // bill = (TotalAmount+(payload.data.amount*payload.requestedQty));
          console.log("bill is"+bill)

            setbillAmount(bill)
          setTotalAmount(bill.toFixed(2))
          setTotalPayable(payablecalculate)
     setTotalPayableAddDisc(payablecalculate.toFixed(2))



          }
          // cartItems.map((item)=>{
          //   console.log("data in map"+item.system_id)
          //   if(item.system_id==payload.data.system_id){
          //      alert("This item already add into cart")
          //     // payload.data.requestedQuantity=parseInt(payload.requestedQty);
          //     // setcartItems([...cartItems,payload.data]);
          //     // bill = billAmount+(payload.data.price_Offered*payload.requestedQty);
          //     // setbillAmount(bill)
      
          //     }
          //   else{
          //     alert("This item already add into cart new")
          //   payload.data.requestedQuantity=parseInt(payload.requestedQty);
          //   setcartItems([...cartItems,payload.data]);
          //   bill = billAmount+(payload.data.price_Offered*payload.requestedQty);
          //   setbillAmount(bill)
          //      }
          //     })
        }
          // if(cartItems.length===0)
          // {
          //   setcartItems([...cartItems,payload]);
          //   bill = billAmount+(payload.data.price_Offered*payload.requestedQty);
          //   setbillAmount(bill);
          // }
          
        
        break;
        case "REMOVE_FROM_CART":
          let removeBill = 0;
           let updatedCartList = cartItems?.filter((cart)=>cart.systemId!==payload.data.systemId);
           if(updatedCartList.length==0)
           {
          //  cartItems.splice(payload.index,1)

             setcartItems([]);
             TotalCalculation()
             setTotalAmount(0)
             setTotalPayable(0)
             setTotalDiscount(0)
             setTotalPayableAddDisc(0)
             setTotalRefundDiscountAmount(0)
             setTotalRfdAmount(0)
             setTotalRfdPayable(0)
             setTotalRfdPayableAddDisc(0)
             console.log("me chala hun")
            //  setbillAmount(0);
           }
           else{
            removeBill = billAmount-payload.deductedAmount;
            setcartItems([...cartItems,updatedCartList]);
           cartItems.splice(payload.index,1)
            // setbillAmount(removeBill);
            setcartItems([...cartItems]);
            TotalCalculation()
   
           }
          console.log('list after removing the items : ',updatedCartList);
         break;
        case "INCREASE_QTY":
          console.log("Payload value Increase Qty ")
          console.log(payload)

          const index = cartItems.findIndex(object => {
            return object.systemId === payload.itemIndex;
          });
          console.log("index is "+index)
          cartItems[index].qty=payload.qty;
          cartItems[index].amount=parseFloat((payload.updatedAmount));


          // for rfd 9-jan-2023
          cartItems[index].rfd=payload.rfd;
          cartItems[index].rfdAmount=(payload.updatedRfdAmount); 


          // cartItems[index].netvalue=payload.updatedAmount;

          //15-dec
          // setbillAmount( payload.updatedAmount);
          console.log("chekkk"+JSON.stringify(cartItems[index]))
          let newBillAmount = payload.updatedAmount;
           
          console.log("new bill amount"+newBillAmount)
          TotalCalculation()
          // setcartItems(...cartItems)
  // setbillAmount(newBillAmount);
          break;
          case "PERCENTAGEUPDATE":
            console.log("Payload value Increase Qty "+JSON.stringify(payload))
            const indexNew = cartItems.findIndex(object => {
              return object.systemId === payload.itemIndex;
            });
            console.log("index is "+indexNew)
          // cartItems[index].qty=payload.qty;

            cartItems[indexNew].disc=payload.disc;
            let discValue=((payload.disc)/100)*payload.updatedAmount
            cartItems[indexNew].discount=discValue.toFixed(2);  
            console.log("check disc amount"+discValue)
            cartItems[indexNew].netvalue=(payload.updatedAmount-discValue).toFixed(2); 
              // for refund amount percentage apply kr rha hun  jo oper TotalCalculation me likha hai
//             let discValueForRefund=parseFloat((((payload.disc)/100)*payload.updatedRfdAmount).toFixed(2))
// setTotalRefundDiscountAmount(TotalRefundDiscountAmount+discValueForRefund)
            // cartItems[indexNew].netvalue=payload.updatedAmount;
  
            //15-dec
            // setbillAmount( payload.updatedAmount);
            console.log("chekkk"+JSON.stringify(cartItems[index]))
            // let newBillAmount = payload.updatedAmount;
            console.log("new bill amount"+newBillAmount)
            // setcartItems(...cartItems)
    // setbillAmount(newBillAmount);
    TotalCalculation()

            break;
            case "SpecialDiscount":
        // let bill=0;
         console.log('Add to Cart in App.js Payload is : ',payload);
        //  let val=TotalAmount-payload.specialDiscountVal
        //  setTotalPayable(payload.specialDiscountVal)
        // setTotalPayable(payload.specialDiscountPer)
        if(payload.onChangeValue==0){
          setTotalPayableAddDisc(TotalPayable)
        }
        else{
        setTotalPayableAddDisc( payload.specialDiscountPer)

        }
        //  setbillAmount2(2)
       
        //  console.log('chk update',billAmount);
          
        
        break;
         case "UpdateInvoice":
          // let bill=0;
           console.log('Add to Cart in App.js Payload is : ',payload.updateArray);
           setcartItems(payload.updateArray)
          
           TotalCalculationUpdate(payload.updateArray)
          //  let val=TotalAmount-payload.specialDiscountVal
          //  setTotalPayable(payload.specialDiscountVal)
          // if(payload.onChangeValue==0){
          //   setTotalRfdPayableAddDisc(TotalRfdPayable)
          // }
          // else{
          // setTotalRfdPayableAddDisc( payload.rfdSpecialDiscountPerAmount)
  
          // }
          // setTotalRfdPayable(payload.rfdSpecialDiscountPerAmount)
  
          //  setbillAmount2(2)
         
          //  console.log('chk update',billAmount);
            
          
          break;
        case "RfdSpecialDiscount":
          // let bill=0;
           console.log('Add to Cart in App.js Payload is : ',payload);
          //  let val=TotalAmount-payload.specialDiscountVal
          //  setTotalPayable(payload.specialDiscountVal)
          if(payload.onChangeValue==0){
            setTotalRfdPayableAddDisc(TotalRfdPayable)
          }
          else{
          setTotalRfdPayableAddDisc( payload.rfdSpecialDiscountPerAmount)
  
          }
          // setTotalRfdPayable(payload.rfdSpecialDiscountPerAmount)
  
          //  setbillAmount2(2)
         
          //  console.log('chk update',billAmount);
            
          
          break;
      case "ClearData":
        // let bill=0;
        //  console.log('Add to Cart in App.js Payload is : ',payload);
        //  setbillAmount2(2)
       
        //  console.log('chk update',billAmount);
        setcartItems([])
        setTotalAmount(0)
        setTotalDiscount(0)
        setTotalPayable(0)
        setTotalPayableAddDisc(0)
        setTotalRfdAmount(0)
        setTotalRfdPayable(0)
        setTotalRfdPayableAddDisc(0)
        setbillAmount(0)
          
        
        break;
     
      default:
        break;
    }
   }
  return (
    <ProductContex.Provider value={{ dispatcchUserEvents,billAmount,cartItems,TotalAmount,TotalDiscount,TotalPayable,TotalPayableAddDisc,TotalRfdAmount,TotalRfdPayable,TotalRfdPayableAddDisc,TotalRefundDiscountAmount}}>
      {children}
    </ProductContex.Provider>
  );
};

ReactDOM.render(
 
<MyContextProvider>
  <BrowserRouter history={hist}>
    <Switch>
      <Route exact path="/" component={Components} />
      <Route path="/Login" component={LoginPage} />

      <Route exact path="/Home" component={Components} />
      <Route path="/Invoice" component={InvoiceNew} />
      <Route path="/InvoiceUpdate" component={InvoiceUpdate} />
      <Route path="/InvoicePdf" component={InvoicePdf} />
      <Route path="/AddNewClient" component={AddNewClient} />
      <Route path="/InvoiceNewQrCode" component={InvoiceNewQrCode} />


      

      


      {/* for future scientific  */}
      {/* <Route exact path="/Products" component={Products} />
      <Route exact path="/Principles" component={Principles} /> */}


      {/*  */}
      <Route path="/FindTest" component={FindATest} />
{/* NewsAndFeeds 31-dec-2022 */}

      <Route path="/NewsAndFeed" component={NewsAndFeed} />

      <Route path="/GerryOnlyExcelExport" component={GerryOnlyExcelExport} />

      <Route path="/profile-page" component={ProfilePage} />
      <Route path="/HomeSampling" component={HomeSampling} />
      <Route path="/COVID19_HomeSampling" component={COVID19_HomeSampling} />
      <Route path="/Booking_History" component={Booking_History} />
      <Route path="/Overall_Covid_Reports" component={Overall_Covid_Reports} />
      <Route path="/ResetPassword" component={ResetPassword} />
      {/* <Route path="/Clinic_Chemistry&Immunology" component={Clinic_Chem} /> */}
      {/* <Route path="/Ultrasound" component={Ultrasound} /> */}
      <Route path="/WhoWeAre" component={WhoWeAre} />
      {/* <Route path="/Molecular" component={Molecular} /> */}
      {/* <Route path="/Histopathology" component={Histopathology} /> */}
      {/* <Route path="/Hematology" component={Hematology} /> */}
      {/* <Route path="/Echocardiography" component={Echocardiography} /> */}
      <Route path="/OurMission" component={OurMission} />
      {/* <Route path="/Fibroscan" component={Fibroscan} /> */}
      <Route path="/Covid" component={Covid} />
      {/* <Route path="/Special_Pathology" component={Special_Pathology} /> */}
      {/* <Route path="/Microbiology&ClinicalPathology" component={Microbiology} /> */}
      <Route path="/Register" component={RegisterPage} />
      <Route path="/RecallPassword" component={RecallPassword} />
      <Route path="/Quotation" component={Quotation} />
      <Route path="/QuotationWithParam" component={QuotationWithParam} />

      <Route path="/Emirates" component={Emirates} />
      {/* <Route path="/Lab_Reports" component={Lab_Reports} /> */}
      <Route path="/AirBlue" component={AirBlue} />
      <Route path="/AirBlue_COVID19_PCR" component={AirBlueSummary} />
      <Route path="/Summary" component={Summary} />
      <Route path="/FlyDubaiExcelExport" component={FlyDubaiExcelExport} />
      <Route path="/KSA_Reports" component={CovidReports} />
      <Route path="/Sample_Tracking" component={Sample_Tracking} />
      <Route path="/View_Sample_Tracking" component={View_Sample_Tracking} />
      <Route path="/Update_Tracking" component={Update_Sample_Tracking} />
      <Route path="/FlyDubai" component={FlyDubai} />
      <Route path="/FlyDubai_Covid19_PCR" component={FlyDubaiReports} />

      <Route path="/ExportKSACases" component={ExportKSACases} />

      <Route path="/QatarLogin" component={QatarLogin} />

      <Route path="/Qatar_Covid19_PCR" component={QatarSummary} />

      <Route path="/QatarExcelExport" component={QatarExcelExport} />
      <Route path="/Bookings" component={Bookings} />

      <Route path="/Onix_Enterprises" component={Onix_Enterprises} />

      <Route path="/OnixEnterprises_COVID19_PCR" component={Onix_Summary} />

      <Route path="/GerrysExcelExport" component={GerrySummary} />
      <Route path="/SereneAir" component={SereneAir} />

      <Route path="/SereneAir_COVID19_PCR" component={SereneAir_Summary} />


      <Route path="/EtihadLogin" component={EtihadLogin} />

      <Route path="/EtihadAirways_COVID19_PCR" component={Etihad_Summary} />


      <Route path="/Overall_Audit" component={Gerrys_UTF_Service} />
      <Route path="/OmanAir" component={OmanAir} />

      <Route path="/OmanAir_COVID19_PCR" component={OmanAir_Summary} />

      <Route path="/Travelers" component={Travelers} />
      <Route path="/AirBlue_Export" component={AirBlueExport} />
      {/* 07-march-2023 */}
      <Route path="/Printing" component={Printing} />
      {/* 25-march-2023 */}
      <Route path="/Invoice" component={Invoice}/>
      {/* 21-july-2023 */}
      <Route path="/CompanyProfile" component={CompanyProfile}/>
      {/* 31-july-2023 */}
      {/* <Route path="/DigitalXray" component={DigitalXray}/> */}
      {/* <Route path="/OPG" component={OPG}/> */}
      {/* <Route path="/Spirometry" component={Spirometry}/> */}
      {/* 03-aug-2023 */}
      {/* <Route path="/CeoMessage" component={CeoMessage} /> */}
       {/* 03-aug-2023 */}
       {/* <Route path="/OurTeam" component={OurTeam} /> */}
{/* 01-sep-2023 */}
{/* <Route path="/OurGallery" component={OurGallery} /> */}
      

    </Switch>
  </BrowserRouter>

  </MyContextProvider>
  ,
  document.getElementById("root")
);
